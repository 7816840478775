import {i18n, k} from '@i18n/translate';
import Settings from './Settings.view';
import {Skeleton} from 'antd';
import {getDisconnectCalendarRm} from '@store/apiEndpoints';
import {getSettingsRq} from '@store/apiEndpoints/user/queries';
import {getUpdateSettingsRm} from '@store/apiEndpoints/user/mutations';
import {useMutation, useQuery} from 'react-query';
import {UserSettingsVM} from '@models/serverModels';
import {
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useState} from 'react';
import {
  CalendarCallbackRedirect,
  CalendarProvider,
  LanguagePreference,
} from '@generated/enums';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useGetCurrencyExchangeQuery} from '@hooks/apiEndpoints/localization/queries';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import learnInConfirm from '@components/reusable/learnInConfirm';
import {changeUsersLanguage, LANGUAGE, getUsersLocale} from '@utils/l10nUtils';
import {LANGUAGE_LOCAL_STORAGE_KEY} from '@utils/constants';
import {isStripeTermsAvailable} from '@components/CardIssuerTerms/CardIssuerTerms.shared';
import {useConnectCalendarMutation} from '@generated/hooks';
import {useBackToLxpLink} from '@hooks/integrated/useBackToLxpLink';
import {useIsLxpAndAcademiesUser} from '@hooks/integrated/useIsLxpAndAcademiesUser';
import {UserPaths} from '@utils/ClientPaths';
import {useNavigate} from 'react-router-dom';
import {LxpPaths} from '@utils/lxpPaths';
import {useUrlContainsIndicator} from '@hooks/integrated/useUrlContainsIndicator';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {getAvailableEmailSettings} from './Settings.hooks';
import {useIntegratedMode} from '@hooks/integrated/useIntegratedMode';

function SettingsContainer({
  renderPageInsteadOfDrawer,
}: {
  renderPageInsteadOfDrawer: boolean;
}) {
  const {isFeatureFlagOn} = useFeatureFlags();
  useSetPageTitle(i18n.t(k.SETTING__PLURAL));

  const {data: currentUser, invalidate: invalidateCurrentUserData} =
    useGetUserQuery();

  const {invalidate: invalidateCurrencyData} = useGetCurrencyExchangeQuery();

  // Settings

  const settingsRq = getSettingsRq();
  const settingsQuery = useQuery<UserSettingsVM>(settingsRq.queryKey, () =>
    simpleQueryFn(settingsRq.path)
  );

  // timeout allows for notification to show
  const reloadPage = () => setTimeout(() => location.reload(), 200);

  const maybeChangeLanguage = (language: LanguagePreference) => {
    const languagePreference = LanguagePreference[language];
    const localeCode = LANGUAGE[languagePreference].locale;
    if (getUsersLocale() !== localeCode) {
      changeUsersLanguage(localeCode);
      reloadPage();
    }
  };

  const updateSettings = (settings: UserSettingsVM) => {
    const normalLocale =
      settings.languagePreference >= 0 || !isFeatureFlagOn.PseudoLocale;
    if (normalLocale) {
      updateSettingsMutation.mutate(settings);
    } else {
      notify.settingsUpdateSuccess();
      changeUsersLanguage(LANGUAGE.EN_JM.locale.toLowerCase());
      reloadPage();
    }
  };

  const updateSettingsMutation = useMutation(
    (settingsData: UserSettingsVM) => {
      const isPseudoLanguage = settingsData.languagePreference < 0;
      if (isPseudoLanguage && isFeatureFlagOn.PseudoLocale) {
        settingsData.languagePreference = LANGUAGE.EN_US.enum;
      }
      const updateSettings = getUpdateSettingsRm(settingsData);
      return simpleMutationFn<UserSettingsVM>(
        updateSettings.path,
        updateSettings.payload
      );
    },
    {
      onError: () => {
        notify.settingsUpdateError();
      },
      onSuccess: async (_: UserSettingsVM, variables: UserSettingsVM) => {
        await simpleInvalidateExactQueryFn(settingsRq.queryKey);
        await settingsQuery.refetch();
        invalidateCurrentUserData();
        invalidateCurrencyData();
        notify.settingsUpdateSuccess();
        maybeChangeLanguage(variables.languagePreference);
      },
    }
  );

  const [googleCalConnected, setGoogleCalConnected] = useState(
    CalendarProvider.Google === currentUser?.connectedCalendar
  );
  const [outlookCalConnected, setOutlookCalConnected] = useState(
    CalendarProvider.Outlook === currentUser?.connectedCalendar
  );

  const navigate = useNavigate();

  const linkToLxpAcademies = useBackToLxpLink(currentUser, {
    type: 'settings',
  });

  const containsExternalUrlParam = useUrlContainsIndicator(
    location.href,
    isFeatureFlagOn.LXPAdminLayout
  );
  const isIntegratedMode = useIntegratedMode(currentUser);
  const viaUnknownExternal =
    (isFeatureFlagOn.LxpToCmFlow || isIntegratedMode) &&
    !containsExternalUrlParam;

  function connectOrDisconnectCalendar({
    calendarProvider,
    makingSelection,
    disconnectOther,
    calendarId,
  }: {
    calendarProvider: CalendarProvider;
    makingSelection: boolean;
    disconnectOther: boolean;
    calendarId?: number;
  }) {
    if (!makingSelection || disconnectOther) {
      disconnectCalendarMutation.mutateAsync().then(() => {
        const modalRef = learnInConfirm({
          content: i18n.t(k.CALENDAR__DISCONNECT__DESCRIPTION__FORMAT, {
            calendarProvider: String(CalendarProvider[calendarProvider]),
          }),
          title: i18n.t(k.CALENDAR__DISCONNECT),
          okText: i18n.t(k.CTA__OK),
          showCancel: false,
          onOk: () => modalRef.destroy(),
        });
      });
    }
    if (makingSelection) {
      connectCalendar.mutate({
        payload: {
          id: calendarId,
          calendar: calendarProvider,
          redirectOption: CalendarCallbackRedirect.UserSettings,
        },
      });
    }
  }

  function updateCalendarState(
    calendarProvider: CalendarProvider,
    disconnectOtherCal: boolean
  ) {
    switch (calendarProvider) {
      case CalendarProvider.Google:
        setGoogleCalConnected(!googleCalConnected);
        disconnectOtherCal && setOutlookCalConnected(false);
        break;
      case CalendarProvider.Outlook:
        setOutlookCalConnected(!outlookCalConnected);
        disconnectOtherCal && setGoogleCalConnected(false);
        break;
      default:
        break;
    }
  }

  function handleCalendarConnection(
    calendarProvider: CalendarProvider,
    makingSelection: boolean,
    disconnectOther?: boolean,
    calendarId?: number
  ) {
    connectOrDisconnectCalendar({
      calendarProvider,
      makingSelection,
      calendarId,
      disconnectOther,
    });
    updateCalendarState(calendarProvider, disconnectOther);
  }

  const connectCalendar = useConnectCalendarMutation({
    onSuccess: (applicationUrl: string) => {
      // Redirect to the URL built up by our API
      window.location.href = applicationUrl;
    },
  });

  const disconnectCalendarMutation = useMutation(
    () => {
      const disconnectCalendar = getDisconnectCalendarRm();
      return simpleMutationFn<string>(
        disconnectCalendar.path,
        disconnectCalendar.payload
      );
    },
    {
      onError: notify.disconnectCalendarError,
      onSuccess: notify.disconnectCalendarSuccessful,
    }
  );

  const {isAdmin, isManager} = useAuth();
  const isPseudoLocaleLocalStorage =
    localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY).toLowerCase() ===
    LANGUAGE.EN_JM.locale.toLowerCase();
  const languagePreference =
    isFeatureFlagOn.PseudoLocale && isPseudoLocaleLocalStorage
      ? LANGUAGE.EN_JM.enum
      : (settingsQuery.data?.languagePreference ?? LANGUAGE.EN_US.enum);
  const isLxpAndAcademiesUser = useIsLxpAndAcademiesUser(
    currentUser?.isAcademiesIntegratedExperienceDisabled
  );
  const showProfileSettings =
    isLxpAndAcademiesUser || !renderPageInsteadOfDrawer;
  const showIntegrationsSettings =
    !isLxpAndAcademiesUser && isFeatureFlagOn.ConnectCalendar;
  const showCurrencySettings = !isLxpAndAcademiesUser;
  const showStripeLinks =
    !isLxpAndAcademiesUser &&
    isStripeTermsAvailable(
      currentUser?.plbType,
      !currentUser?.hasAcceptedStripeTerms
    );
  const showContributorUpdateCheckbox = isFeatureFlagOn.Academies;

  const settingsCheckboxes = getAvailableEmailSettings({
    limitEmailPreferences: isLxpAndAcademiesUser,
    isAdmin,
    isManager,
    showContributorUpdateCheckbox,
    allowEmails: settingsQuery.data?.notificationSettings?.allowEmails,
  });

  const onCloseWithLxpOption =
    renderPageInsteadOfDrawer || isLxpAndAcademiesUser
      ? () => {
          if (isLxpAndAcademiesUser) {
            window.history.back();
          } else if (viaUnknownExternal) {
            window.location.href = LxpPaths.Dashboard;
          } else {
            linkToLxpAcademies
              ? (window.location.href = linkToLxpAcademies)
              : navigate(UserPaths.ManagePlan);
          }
        }
      : null;

  return (
    <div>
      {settingsQuery.isLoading ||
      settingsQuery.isError ||
      !settingsQuery.data ? (
        <Skeleton active />
      ) : (
        <Settings
          languagePreference={languagePreference}
          isFeatureFlagOn={isFeatureFlagOn}
          data={settingsQuery.data}
          updateSettings={updateSettings}
          isGoogleCalendarOn={isFeatureFlagOn.GoogleCalendar}
          googleCalConnected={googleCalConnected}
          outlookCalConnected={outlookCalConnected}
          handleCalendarConnection={handleCalendarConnection}
          settingsCheckboxes={settingsCheckboxes}
          showContributorUpdateCheckbox={showContributorUpdateCheckbox}
          showCurrencySettings={showCurrencySettings}
          showIntegrationsSettings={showIntegrationsSettings}
          showProfileSettings={showProfileSettings}
          showStripeLinks={showStripeLinks}
          onCloseWithLxpOption={onCloseWithLxpOption}
          viaUnknownExternal={viaUnknownExternal}
        />
      )}
    </div>
  );
}

export default SettingsContainer;
