import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';
import {useHasIntegratedUx} from './useHasIntegratedUx';
import {useUrlContainsIndicator} from './useUrlContainsIndicator';
import {UserDetailsVM} from '@generated/interfaces';
import {INTEGRATED_INCOMING_LXP_USER} from '@utils/constants';
import {useIntegratedMode} from './useIntegratedMode';

/** If directly navigating from LXP, we hide the primary navigation for integrated users, regardless of approver status */
export const useForceHidePrimaryNavigation = (
  user: UserDetailsVM,
  isFeatureFlagOn: TIsFeatureFlagOn,
  isApprover: boolean,
  isLxpAdmin: boolean
) => {
  const isIntegratedMode = useIntegratedMode(user);
  const hasIntegratedUx = useHasIntegratedUx(isIntegratedMode, isApprover);
  const isIncomingLxpUser =
    sessionStorage.getItem(INTEGRATED_INCOMING_LXP_USER) === 'true';
  const hideIfUrlIndicates = useUrlContainsIndicator(
    location.href,
    isFeatureFlagOn.LXPAdminLayout
  );

  if (isIncomingLxpUser) return true;
  if (hasIntegratedUx && isLxpAdmin && !isFeatureFlagOn.LXPAdminLayout)
    return true;
  if (!isFeatureFlagOn.UpdatedLxpToCmFlow) return false;

  return isIntegratedMode
    ? hideIfUrlIndicates || hasIntegratedUx
    : hideIfUrlIndicates;
};
