import {useState} from 'react';
import {useParams} from 'react-router-dom';
import Grid from '@blocks/Grid';
import {openMessage} from '@components/user/notifications';
import {InputLabel} from '@components/reusable/LearnInForm';
import Select from 'react-select';
import {i18n, k} from '@i18n/translate';
import React from 'react';
import {AllowSkipCheckbox, Description, DueDate} from './Form';
import {FORM_KEYS} from './Form/constants';
import {getInitialDueDateAndTime} from './Form/utils';
import {FormProps} from './AddEditContent.config';
import NotificationContent from '@components/user/NotificationContent';
import {useSkillsAssociatedByIdQuery} from '@generated/hooks';
import {ResourceSkillType} from '@generated/enums';
import {Form} from 'antd';

const SkillRatingForm: React.FC<FormProps> = ({
  step,
  canShowAllowSkip,
  stepRequirementComponent,
}) => {
  const {academyId} = useParams();
  const initialValues = getInitialDueDateAndTime(step);
  const [inputValue, setInputValue] = useState('');
  const form = Form.useFormInstance();
  form.setFieldsValue({[FORM_KEYS.TITLE]: ''});
  // Fetch skills
  const {data: skillTags, isLoading} = useSkillsAssociatedByIdQuery(
    {
      resourceId: parseInt(academyId || 'NaN'),
      type: ResourceSkillType.Academy,
    },
    {
      enabled: !!academyId,
      onError: () => {
        openMessage(
          'error',
          NotificationContent(i18n.t(k.NOTIFICATION__LOAD_SKILLS_ERROR))
        );
      },
      onSuccess: (e) => {
        const id = parseInt(step.title);
        if (id) {
          const selectedSkill = e.find((s) => s.id === parseInt(step.title));
          if (selectedSkill) {
            form.setFieldsValue({[FORM_KEYS.LXP_INPUT_ID]: id});
          }
        }
      },
    }
  );

  return (
    <Grid.Container>
      <Grid.Box>
        <InputLabel
          htmlFor="skill-input"
          label={i18n.t(k.GENERIC__SKILL)}
          required
        />
        <Form.Item
          style={{marginBottom: '0px'}}
          name={FORM_KEYS.LXP_INPUT_ID}
          getValueFromEvent={(option) => option?.id}
          getValueProps={(value) => {
            // For display purposes, find the option with matching ID
            const option = skillTags?.find((item) => item.id === value);
            return {
              value: option
                ? {
                    ...option,
                    label: option.title,
                    value: option.id,
                  }
                : undefined,
            };
          }}>
          <Select
            data-testid="skill-select"
            isLoading={false}
            inputValue={inputValue}
            isClearable={false}
            onInputChange={setInputValue}
            options={skillTags?.map((item) => ({
              ...item,
              label: item.title,
              value: item.id,
            }))}
            placeholder={i18n.t(k.CTA__SEARCH_SKILLS)}
            noOptionsMessage={({inputValue}) => {
              return inputValue?.length >= 2
                ? i18n.t(k.LABEL__SEARCH_NO_SKILLS_FOUND)
                : null;
            }}
            onChange={(e) => {
              form.setFieldsValue({[FORM_KEYS.LXP_INPUT_ID]: e.id});
            }}
            isDisabled={isLoading}
          />
        </Form.Item>
        <p>{i18n.t(k.RATING__ADD_STEP_TITLE_HELPER)}</p>
      </Grid.Box>

      <Grid.Box>
        <Description initialValue={step?.description} richTextUploads={false} />
      </Grid.Box>

      <DueDate
        initialDueDate={initialValues?.initialDueDate}
        initialDueDateTime={initialValues?.initialDueDateTime}
        initialTimeZoneId={step?.dueDateTimeZone}
      />

      {canShowAllowSkip && (
        <Grid.Box full style={{marginBottom: 12}}>
          <AllowSkipCheckbox initialValue={step?.allowSkip} />
        </Grid.Box>
      )}
    </Grid.Container>
  );
};

export default SkillRatingForm;
