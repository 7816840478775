import {CSSProperties, ReactElement, ReactNode} from 'react';
import {i18n, k} from '@i18n/translate';
import styled, {DefaultTheme} from 'styled-components';
import {
  ArrowRightOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
  LoadingOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import {COLORS} from '@utils/constants';

import {ButtonStyles} from './ButtonTheme';
import {ButtonTags} from './ButtonEnums';
import {ButtonProps} from 'antd/lib/button';
import {ButtonHTMLType} from 'antd/lib/button/button';

import {Spin} from 'antd';
import {Breakpoints} from '@utils/responsiveUtils';
import Icon, {IconSizes} from '@blocks/Icon/Icon';

const StyledButton = styled.button`
  align-items: center;
  background: ${(props: DefaultTheme) =>
    props?.background || ButtonStyles[props.tag]?.background};
  border-radius: ${(props: DefaultTheme) =>
    props?.borderRadius || ButtonStyles[props.tag]?.borderRadius};
  border: ${(props: DefaultTheme) =>
    props?.border || ButtonStyles[props.tag]?.border};
  color: ${(props: DefaultTheme) =>
    props?.color || ButtonStyles[props.tag]?.color};
  cursor: pointer;
  display: flex;
  font-family: ${(props: DefaultTheme) =>
    props?.fontFamily || ButtonStyles[props.tag]?.fontFamily};
  font-size: ${(props: DefaultTheme) =>
    props?.fontSize || ButtonStyles[props.tag]?.fontSize};
  font-weight: ${(props: DefaultTheme) =>
    props?.fontWeight || ButtonStyles[props.tag]?.fontWeight};
  justify-content: center;
  letter-spacing: ${(props: DefaultTheme) =>
    props?.letterSpacing || ButtonStyles[props.tag]?.letterSpacing};
  line-height: ${(props: DefaultTheme) =>
    props?.lineHeight || ButtonStyles[props.tag]?.lineHeight};
  padding: ${(props: DefaultTheme) => {
    if (props?.noPadding) return '0';
    if (props?.icon)
      return props?.iconPadding || ButtonStyles[props.tag]?.iconPadding;
    return props?.padding || ButtonStyles[props.tag]?.padding;
  }};
  transition: 0.2s;
  gap: 0.5rem;

  &:hover {
    background: ${(props: DefaultTheme) =>
      props?.hoverBackground || ButtonStyles[props.tag]?.hoverBackground};
    border: ${(props: DefaultTheme) =>
      props?.hoverBorder || ButtonStyles[props.tag]?.hoverBorder};
    color: ${(props: DefaultTheme) =>
      props?.hoverColor || ButtonStyles[props.tag]?.hoverColor};
    text-decoration: ${(props: DefaultTheme) =>
      props?.hoverUnderline ? 'underline' : null};
  }

  &:hover .learnin-icon {
    color: ${(props: DefaultTheme) =>
      props?.hoverColor || ButtonStyles[props.tag]?.hoverColor};
  }

  &:focus-visible {
    outline: ${(props: DefaultTheme) =>
      props?.focusOutline || ButtonStyles[props.tag]?.focusOutline};
    outline-offset: ${(props: DefaultTheme) =>
      props?.focusOutlineOffset || ButtonStyles[props.tag]?.focusOutlineOffset};
    color: ${(props: DefaultTheme) =>
      props?.focusColor || ButtonStyles[props.tag]?.focusColor};
    transition: none;
    text-decoration: ${(props: DefaultTheme) =>
      props?.hoverUnderline ? 'underline' : null};
  }

  &:active {
    background: ${(props: DefaultTheme) =>
      props?.activeBackground || ButtonStyles[props.tag]?.activeBackground};
    border-color: ${(props: DefaultTheme) =>
      props?.activeBorderColor || ButtonStyles[props.tag]?.activeBorderColor};
    color: ${(props: DefaultTheme) =>
      props?.activeColor || ButtonStyles[props.tag]?.activeColor};
  }

  &:disabled {
    background: ${(props: DefaultTheme) =>
      props?.disabledBackground || ButtonStyles[props.tag]?.disabledBackground};
    border: ${(props: DefaultTheme) =>
      props?.disabledBorderColor ||
      ButtonStyles[props.tag]?.disabledBorderColor};
    color: ${(props: DefaultTheme) =>
      props?.disabledColor || ButtonStyles[props.tag]?.disabledColor};
    cursor: default;
  }

  & ~ button {
    margin-left: 8px;
    @media (max-width: ${Breakpoints.Tablet}px) {
      margin-left: 0;
    }
  }
`;

const StyledSpinner = styled.div`
  height: 0;
  margin-right: 10px;

  .ant-spin {
    color: ${(props: DefaultTheme) =>
      props?.disabledColor || ButtonStyles[props.tag]?.disabledColor};
    margin-top: -15px;
    &-dot {
      display: flex;
      align-items: baseline;
    }
  }

  .anticon-spin {
    font-size: 1rem;
  }
`;

export interface LearnInButtonProps extends ButtonProps {
  children?: ReactNode;
  showCloudIcon?: boolean;
  showArrowIcon?: boolean;
  showIcon?: boolean;
  tag: ButtonTags;
  chevron?: boolean;
  isChevronOpen?: boolean;
  loading?: boolean;
  // overrides
  activeBackground?: string;
  activeBorderColor?: string;
  activeColor?: string;
  background?: string;
  border?: string;
  borderRadius?: string;
  color?: string;
  disabledBackground?: string;
  disabledBorderColor?: string;
  disabledColor?: string;
  focusBackground?: string;
  focusBorderColor?: string;
  focusOutline?: string;
  focusOutlineOffset?: string;
  focusColor?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  hoverBackground?: string;
  hoverBorder?: string;
  hoverColor?: string;
  hoverUnderline?: boolean;
  htmlType?: ButtonHTMLType;
  icon?: ReactElement;
  iconSize?: IconSizes;
  letterSpacing?: string;
  lineHeight?: string;
  padding?: string;
  role?: string;
  ['data-testid']?: string;
  noPadding?: boolean;
  iconAlign?: 'left' | 'right';
  ['data-cy']?: string;
}

/**
 *
 * This is the standard button that should be used in all places where buttons are needed. You may need to
 * add new tags to this button if you are implementing a new type of button. The default button should cover
 * most use cases  but you can pass in props to override styling if necessary.
 */
export function LearnInButton({children, ...rest}: LearnInButtonProps) {
  const LoadingIcon = (
    <span aria-label={i18n.t(k.STATUS__LOADING)}>
      <LoadingOutlined aria-hidden="true" spin />
    </span>
  );
  return (
    <StyledButton {...rest} disabled={rest.disabled || rest.loading}>
      {rest.loading && (
        <StyledSpinner {...rest}>
          <Spin indicator={LoadingIcon} />
        </StyledSpinner>
      )}
      {rest.showCloudIcon && rest.tag === ButtonTags.Primary && (
        <CloudDownloadOutlined
          aria-hidden="true"
          style={{marginRight: '5px'}}
        />
      )}
      {rest.icon && rest.iconAlign !== 'right' && (
        <Icon
          hoverColor={rest?.hoverColor}
          color={rest?.color || ButtonStyles[rest.tag]?.color}
          icon={rest.icon}
          size={rest.iconSize}
        />
      )}
      {children}
      {rest.icon && rest.iconAlign === 'right' && (
        <Icon
          hoverColor={rest?.hoverColor}
          color={rest?.color || ButtonStyles[rest.tag]?.color}
          icon={rest.icon}
          size={rest.iconSize}
        />
      )}
      {rest.showIcon && rest.tag === ButtonTags.Primary && (
        <CheckOutlined aria-hidden="true" style={{marginLeft: '5px'}} />
      )}
      {rest.showIcon && rest.tag === ButtonTags.Secondary && (
        <LoginOutlined aria-hidden="true" />
      )}
      {rest.chevron && (
        <CustomChevronIcon
          alt=""
          style={{
            marginLeft: '9px',
            transform: `rotate(${rest.isChevronOpen ? -180 : 0}deg)`,
            transition: 'transform .25s',
            color:
              rest.color ||
              (rest.tag === ButtonTags.Secondary && COLORS.Blue800),
          }}
        />
      )}
      {rest.showArrowIcon && rest.tag === ButtonTags.Link && (
        <ArrowRightOutlined
          aria-hidden="true"
          style={{marginLeft: '9px'}}
          color={COLORS.Blue800}
        />
      )}
    </StyledButton>
  );
}

/*
|--------------------------------------------------------------------------
| Custom Chevron Icon
|--------------------------------------------------------------------------
*/

function CustomChevronIcon({
  style,
  alt,
}: {
  style?: CSSProperties;
  alt?: string;
}) {
  return (
    <svg
      style={style}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>{alt}</title>
      <path
        d="M12.4801 0.480079C12.2848 0.284817 11.9682 0.284817 11.773 0.480079L7.08839 5.16466C7.03957 5.21348 6.96043 5.21348 6.91161 5.16466L2.22703 0.480078C2.03177 0.284816 1.71518 0.284816 1.51992 0.480078L0.989592 1.01041C0.79433 1.20567 0.79433 1.52225 0.989592 1.71751L6.29289 7.02082C6.68342 7.41134 7.31658 7.41134 7.70711 7.02082L13.0104 1.71752C13.2057 1.52225 13.2057 1.20567 13.0104 1.01041L12.4801 0.480079Z"
        fill={style?.color || 'white'}
      />
    </svg>
  );
}

type ChevronDirection = 'up' | 'down' | 'left' | 'right';
interface ToggleChevronProps {
  style?: CSSProperties;
  alt?: string;
  openDirection: ChevronDirection;
  closeDirection: ChevronDirection;
  open: boolean;
}

interface ToggleChevronProps {
  style?: CSSProperties;
  alt?: string;
  openDirection: ChevronDirection;
  closeDirection: ChevronDirection;
  open: boolean;
}

const getRotateDegree = (direction: ChevronDirection): number => {
  switch (direction) {
    case 'up':
      return -180;
    case 'down':
      return 0;
    case 'left':
      return -90;
    case 'right':
      return 90;
  }
};

export const ToggleChevron = ({
  alt,
  style,
  openDirection,
  closeDirection,
  open,
}: ToggleChevronProps) => {
  const rotateDegree = open
    ? getRotateDegree(openDirection)
    : getRotateDegree(closeDirection);
  return (
    <CustomChevronIcon
      style={{
        transform: `rotate(${rotateDegree}deg)`,
        color: COLORS.Black,
        transition: 'transform .25s',
        ...style,
      }}
      alt={alt}
    />
  );
};

export const LinkStyledButton = styled.button`
  color: ${COLORS.Blue800};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
