import {useState, useEffect, useRef} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';
import ContextMenuButton from '@blocks/ContextMenu';
import {LinkVM} from '@models/serverModels';
import {getUploadProjectSubmissionRm} from '@store/apiEndpoints';
import {
  DisplayStatusWarningLevel,
  PlanItemType,
  AcademyStepType,
} from '@generated/enums';
import ContentCard from './ContentCard';
import {formatUrls} from '@utils/stringUtils';
import moment from 'moment';
import {COLORS} from '@utils/constants';
import {DATE_FORMAT, parseToLocalMoment} from '@utils/timeUtils';
import {ContentCompleteButtonContainer} from '@blocks/CustomProgramUserDrawer/ContentCompleteButton';
import {ContentDueDateStatus} from './ContentCard';
import {ProjectSubmissionContentCardFooterContainer} from './ProjectSubmissionContentCardFooter/ProjectSubmissionContentCardFooter.container';
import {DiscussionStepFooter} from './DiscussionStepFooter';
import {SkillAssessmentStepFooterContainer} from './SkillAssessmentStepFooter';
import {SubmissionType} from '@generated/enums';
import useChunkUpload from '@hooks/useChunkUpload';
import {useSkillsAssociatedByIdQuery} from '@generated/hooks';
import {ResourceSkillType} from '@generated/enums';
import ProgramCompletedModal from './ProgramCompletedModal';
import {TOP_NAV_HEIGHT} from '@blocks/appNav/MobileAppHeaderWithMobileMenu';
import useOpenUpload, {useOpenAttachmentUpload} from '@hooks/useOpenUpload';
import {Breakpoints} from '@utils/responsiveUtils';
import {
  AttachmentVM,
  AcademySkillRatingVM,
  UserItemStatusVM,
  CustomProgramContentPreviewVM,
  ContentFeedbackVM,
  MessageDto,
} from '@generated/interfaces';
import {
  ContentType,
  CustomizableLearningCategory,
  DisplayStatus,
  RsvpStatus,
} from '@generated/enums';
import {
  useCustomProgramPreviewQuery,
  useCustomProgramContentAttachmentUrlQuery,
  useSubmitProjectMutation,
  useUserPlanSubmitProjectContentLinkMutation,
  useUserPlanSubmitProjectContentMutation,
  useUpdateCustomProgramContentRsvpMutation,
  useUpdateAcademyStepContentRsvpMutation,
  useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation,
  useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation,
  useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation,
  useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery,
  useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation,
  useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation,
  useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation,
  useCustomProgramsSectionsContentsDiscussionsMessagesQuery,
  useAddItemMutation,
} from '@generated/hooks';
import {CustomProgramPreviewVM} from '@generated/interfaces';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {ForwardOutlined} from '@ant-design/icons';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import useLocalTimeZone from '@hooks/useLocalTimeZone';
import {AttachmentTypeString, ProgramContentStatus} from '@models/clientEnums';
import EventFooterCTAs from './EventFooterCTAs';
import {
  useAcademyLevelsQueryCached,
  useAcademyLevelStepQueryCached,
  useUserAcademyLevelStepQueryCached,
} from '@generated/hooks/academy.get.hooks';
import {notify} from '@components/user/notifications';

/*
|--------------------------------------------------------------------------
| Styling
|--------------------------------------------------------------------------
*/

const DueDate = styled.span`
  align-items: center;
  color: ${COLORS.Neutral900};
  display: flex;
  font:
    500 1.2em Roboto,
    serif;
  margin-bottom: 16px;
`;

const DueDateContainer = styled.span`
  display: flex;
  gap: 1rem;
  @media (max-width: ${Breakpoints.Desktop}px) {
    align-items: start;
    flex-direction: column;
    gap: 0;

    span {
      margin-bottom: 0;
      padding-bottom: 8px;
    }
  }
`;

const EventContainer = styled.span`
  display: flex;
  min-width: 120px;
  @media (max-width: ${Breakpoints.Desktop}px) {
    align-items: start;
    flex-direction: column;
    span {
      margin-bottom: 0;
      padding-bottom: 8px;
    }
  }
`;

const ScrollAnchor = styled.div`
  position: relative;
  top: -${TOP_NAV_HEIGHT + 16}px;
`;

const AcademyEventFooterContainer = styled.div`
  border-top: 1px solid ${COLORS.Neutral200};
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  @media (max-width: ${Breakpoints.Desktop}px) {
    align-items: start;
    flex-direction: column;
  }
`;
const AcademyEventFooterText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-right: 5px;
`;

const JoinEventActions = styled.div`
  margin-left: auto;
  display: flex;
  @media (max-width: ${Breakpoints.Desktop}px) {
    margin-left: 0;
    width: 100%;
    gap: 0.5rem;
    padding-top: 8px;
  }
`;

const ProgramSkippedWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ProgramSkippedText = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 24px 0 8px;
`;

const ProjectWrapper = styled.div`
  border-top: 1px solid ${COLORS.Neutral200};
  @media (max-width: ${Breakpoints.Desktop}px) {
    margin-top: 0;
  }
`;

const TagWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 20px;
  ${({extraStyles}) => ({...extraStyles})};
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const DEFAULT_STATUS = {
  displayStatus: DisplayStatus.Unknown,
  displayStatusWarningLevel: DisplayStatusWarningLevel.Unknown,
  isDone: false,
};

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

export const LiveEventTag = ({
  style,
  eventHasPassed,
}: {
  style?: React.CSSProperties;
  eventHasPassed: boolean;
}) => {
  return (
    <TagWrapper extraStyles={style}>
      <LearnInTag
        label={i18n.t(k.EVENT__LIVE)}
        tagStyle={eventHasPassed ? TagStyles.LightGrey : TagStyles.Green}
      />
    </TagWrapper>
  );
};

/*
|-----------------------------------------------------------
| EVENT FOOTER
|-----------------------------------------------------------
*/

export const AcademyEventFooter = ({
  eventHasPassed,
  rsvpStatus,
  eventLinkUrl,
  contentId,
  isAcademy,
  status,
  skipProgram,
  unskipProgram,
  showSkipProgramButton,
  showUnskipProgramButton,
  onContentCompleteStatusChange,
  isActivePlanItem,
  academyId,
  levelId,
  calendarLinks,
}: {
  academyId: number;
  isActivePlanItem: boolean;
  eventHasPassed?: boolean;
  rsvpStatus?: RsvpStatus;
  eventLinkUrl: string;
  contentId?: number;
  isAcademy: boolean;
  status: UserItemStatusVM;
  showSkipProgramButton: boolean;
  showUnskipProgramButton: boolean;
  skipProgram: (contentId: number) => void;
  unskipProgram: (contentId: number) => void;
  onContentCompleteStatusChange?: (
    contentId: number,
    newStatus: ProgramContentStatus
  ) => void;
  levelId: number;
  calendarLinks: {label: string; url: string}[];
}) => {
  const {customProgramId} = useParams();
  const [localRsvpStatus, setLocalRsvpStatus] = useState<RsvpStatus>();
  useEffect(() => {
    if (rsvpStatus !== localRsvpStatus) {
      setLocalRsvpStatus(rsvpStatus);
    }
  }, [rsvpStatus, localRsvpStatus]);

  const callContentCompleteStatusChanged = () => {
    onContentCompleteStatusChange?.(contentId, ProgramContentStatus.Completed);
  };

  const {invalidateExact: invalidateUserAcademyLevelStep} =
    useUserAcademyLevelStepQueryCached(
      {academyLevelId: levelId},
      {enabled: false}
    );

  const {invalidateExact: invalidateAcademyLevels} =
    useAcademyLevelsQueryCached(
      {academyId, queryParams: {excludeUserProgress: false}},
      {enabled: false}
    );

  const {invalidateExact: invalidateCustomProgram} =
    useCustomProgramPreviewQuery(
      {
        customProgramId: parseInt(customProgramId),
        queryParams: {
          includeProgress: true,
        },
      },
      {enabled: false}
    );

  const showContextMenuForEvents =
    showSkipProgramButton || showUnskipProgramButton;

  const contentCardContextId = `content-card-context-${contentId}`;

  const handleRsvpSuccess = () => {
    if (isAcademy) {
      invalidateAcademyLevels();
      invalidateUserAcademyLevelStep();
    } else if (!!customProgramId) {
      invalidateCustomProgram();
    }
  };

  const updateCustomProgramContentRsvp =
    useUpdateCustomProgramContentRsvpMutation({
      onSuccess: handleRsvpSuccess,
    });

  const updateAcademyStepContentRsvp = useUpdateAcademyStepContentRsvpMutation({
    onSuccess: handleRsvpSuccess,
  });

  const handleClickRsvp = (rsvpStatus: RsvpStatus) => {
    if (!isActivePlanItem) return;
    const payload = {
      contentId,
      rsvpStatus,
    };
    if (isAcademy) {
      updateAcademyStepContentRsvp.mutateAsync({
        payload,
      });
    } else {
      updateCustomProgramContentRsvp.mutateAsync({
        payload,
      });
    }
  };

  if (!contentId) {
    return null;
  }

  return (
    <AcademyEventFooterContainer id={contentCardContextId}>
      <AcademyEventFooterText>
        {status.displayStatus === DisplayStatus.Skipped ? (
          <ProgramSkippedWrapper key="skipped">
            <ForwardOutlined
              aria-hidden="true"
              style={{
                color: COLORS.Neutral600,
                fontSize: '1.375rem',
              }}
            />
            <ProgramSkippedText>{i18n.t(k.STATUS__SKIPPED)}</ProgramSkippedText>
          </ProgramSkippedWrapper>
        ) : eventHasPassed ? (
          i18n.t(k.EVENT__DID_ATTEND)
        ) : (
          i18n.t(k.EVENT__WILL_ATTEND)
        )}
      </AcademyEventFooterText>
      <EventFooterCTAs
        eventHasPassed={eventHasPassed}
        eventLinkUrl={eventLinkUrl}
        status={status}
        calendarLinks={calendarLinks}
        contentCardContextId={contentCardContextId}
      />
      <JoinEventActions>
        {status.displayStatus !== DisplayStatus.Skipped && (
          <>
            <LearnInButton
              aria-label={i18n.t(k.EVENT__ATTENDED_YES)}
              onClick={() => handleClickRsvp(RsvpStatus.Yes)}
              hoverBorder={`1px solid ${COLORS.Neutral900}`}
              hoverColor={COLORS.Neutral900}
              border={`1px solid ${
                localRsvpStatus === RsvpStatus.Yes
                  ? COLORS.Blue800
                  : COLORS.Neutral600
              }`}
              background={
                localRsvpStatus === RsvpStatus.Yes
                  ? COLORS.Blue50
                  : COLORS.White
              }
              color={
                localRsvpStatus === RsvpStatus.Yes
                  ? COLORS.Blue800
                  : COLORS.Neutral600
              }
              activeBackground={
                localRsvpStatus === RsvpStatus.Yes
                  ? COLORS.Blue50
                  : COLORS.White
              }
              activeBorderColor={COLORS.Blue800}
              activeColor={COLORS.Blue800}
              tag={ButtonTags.Secondary}>
              {i18n.t(k.GENERIC__YES)}
            </LearnInButton>
            <LearnInButton
              aria-label={i18n.t(k.EVENT__ATTENDED_NO)}
              onClick={() => handleClickRsvp(RsvpStatus.No)}
              activeBackground={
                localRsvpStatus === RsvpStatus.No ? COLORS.Blue50 : COLORS.White
              }
              activeBorderColor={COLORS.Blue800}
              activeColor={COLORS.Blue800}
              hoverBorder={`1px solid ${COLORS.Neutral900}`}
              hoverColor={COLORS.Neutral900}
              border={`1px solid ${
                localRsvpStatus === RsvpStatus.No
                  ? COLORS.Blue800
                  : COLORS.Neutral600
              }`}
              background={
                localRsvpStatus === RsvpStatus.No ? COLORS.Blue50 : COLORS.White
              }
              color={
                localRsvpStatus === RsvpStatus.No
                  ? COLORS.Blue800
                  : COLORS.Neutral600
              }
              tag={ButtonTags.Secondary}>
              {i18n.t(k.GENERIC__NO)}
            </LearnInButton>
          </>
        )}
        {showContextMenuForEvents && (
          <div style={{marginLeft: '8px'}}>
            <ContextMenuButton
              key="context"
              size="large"
              overlayPlacement="bottomRight"
              popupContainerId={contentCardContextId}
              itemId={contentId}
              menuItems={[
                status.displayStatus !== DisplayStatus.Skipped &&
                  showSkipProgramButton && {
                    label: i18n.t(k.EVENT__SKIP),
                    onClick: () => {
                      if (!isActivePlanItem) return;
                      skipProgram(contentId);
                      callContentCompleteStatusChanged();
                    },
                  },
                status.displayStatus === DisplayStatus.Skipped &&
                  showUnskipProgramButton && {
                    label: i18n.t(k.EVENT__UNSKIP),
                    onClick: () => {
                      if (!isActivePlanItem) return;
                      unskipProgram(contentId);
                      callContentCompleteStatusChanged();
                    },
                  },
              ]}
            />
          </div>
        )}
      </JoinEventActions>
    </AcademyEventFooterContainer>
  );
};

/*
|-----------------------------------------------------------
| EVENT TIME
|-----------------------------------------------------------
*/

export const EventTime = ({
  startDateUtc,
  endDateUtc,
  timeZone,
  style,
}: {
  startDateUtc: string;
  endDateUtc: string;
  timeZone: string;
  style?: React.CSSProperties;
}) => {
  const endTime = !!endDateUtc
    ? parseToLocalMoment(endDateUtc)?.format(DATE_FORMAT.TIME_12_HR)
    : null;
  const startTime = !!startDateUtc
    ? parseToLocalMoment(startDateUtc)?.format(DATE_FORMAT.TIME_12_HR)
    : null;
  const date = !!startDateUtc
    ? parseToLocalMoment(startDateUtc)?.format(DATE_FORMAT.MONTH_LONG_DAY_YEAR)
    : null;
  return (
    <span
      style={{
        display: 'flex',
        fontFamily: 'Roboto',
        fontSize: '1rem',
        fontWeight: 500,
        alignItems: 'center',
        marginBottom: '16px',
        ...style,
      }}>
      {date} {date ? '•' : ''} {startTime}
      {startTime && endTime ? '-' : ''}
      {endTime} {startTime || endTime ? timeZone : ''}
    </span>
  );
};

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/
interface ContentCardContainerProps
  extends Partial<CustomProgramContentPreviewVM> {
  completed?: boolean | null;
  completedOn?: string;
  contentType: ContentType;
  customProgramId?: number;
  levelId?: number;
  dueDate?: string | undefined;
  isActivePlanItem: boolean;
  programIsSelected?: boolean;
  shouldBeInFocus?: boolean;
  showIndexRule: boolean;
  status?: UserItemStatusVM;
  skipProgram?: (contentId: number) => void;
  unskipProgram?: (contentId: number) => void;
  showSkipProgramButton: boolean;
  showUnskipProgramButton: boolean;
  submissionType: SubmissionType;
  submittedFile: AttachmentVM | undefined;
  submittedLink: LinkVM | undefined;
  submittedResponse: string | undefined;
  rsvpStatus?: number;
  customizableLearningCategory: CustomizableLearningCategory;
  onContentCompleteStatusChange?: (
    contentId: number,
    newStatus: ProgramContentStatus
  ) => void;
  showSkipProjectButton: boolean;
  allowPeerVisibility?: boolean;
  peerSubmissionCount?: number;
  academyId?: number;
  feedback?: ContentFeedbackVM[];
  isFullPage: boolean | undefined;
  discussionId?: string;
  messageCount?: number;
  messagesPreview?: MessageDto[];
  userMessages?: MessageDto[];
  calendarLinks?: {label: string; url: string}[];
  skillRatings?: AcademySkillRatingVM[];
  stepType?: AcademyStepType;
}

export default function ContentCardContainer({
  stepType,
  academyId,
  allowPeerVisibility,
  attachments,
  completed,
  contentType,
  customizableLearningCategory,
  customProgramId,
  description,
  dueDate,
  endDateUtc,
  eventLink,
  id,
  isActivePlanItem,
  levelId,
  links,
  onContentCompleteStatusChange,
  order,
  peerSubmissionCount,
  programIsSelected,
  projectContentCompletedOn,
  rsvpStatus,
  shouldBeInFocus,
  showIndexRule,
  showSkipProgramButton,
  showSkipProjectButton,
  showUnskipProgramButton,
  skipProgram,
  startDateUtc,
  status,
  submissionType,
  submittedFile,
  submittedLink,
  submittedResponse,
  title,
  unskipProgram,
  feedback,
  isFullPage,
  discussionId,
  messageCount,
  messagesPreview,
  userMessages,
  calendarLinks,
  skillRatings,
}: ContentCardContainerProps) {
  const {pathname} = useLocation();
  let titleForSkillRating: undefined | string = undefined;
  const isAcademy = pathname.includes('academies');
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const localTimeZone = useLocalTimeZone();

  // Fetching the academy's associated skills to get the most up-to-date name
  // for the skill rating step's title.
  const {data: skillTags, isLoading} = useSkillsAssociatedByIdQuery(
    {
      resourceId: academyId,
      type: ResourceSkillType.Academy,
    },
    {
      enabled: !!academyId && contentType === ContentType.SkillRating,
    }
  );
  if (isLoading) {
    titleForSkillRating = i18n.t(k.STATUS__LOADING);
  }
  if (skillTags) {
    titleForSkillRating = skillTags.find(
      (t) => t.id === parseInt(title)
    )?.title;
  }

  const {invalidateExact: invalidateAcademyLevels} =
    useAcademyLevelsQueryCached(
      {
        academyId,
        queryParams: {excludeUserProgress: false},
      },
      {enabled: false}
    );

  const {refetch: invalidateAcademyLevelStep} = useAcademyLevelStepQueryCached(
    {academyLevelId: levelId},
    {enabled: false}
  );

  const {invalidateExact: invalidateUserAcademyLevelStep} =
    useUserAcademyLevelStepQueryCached(
      {academyLevelId: levelId},
      {enabled: !!levelId && isAcademy}
    );

  const [enableFetchAttachmentUrl, setEnableFetchAttachmentUrl] =
    useState(false);

  const handleSubmitProjectSuccess = async () => {
    await Promise.all([
      refreshDataAfterResponseSubmission(),
      showCompleteModalIfComplete(),
      callContentCompleteStatusChanged(),
      invalidateDiscussionMessages(),
    ]);

    if (customProgramId) {
      invalidateCustomProgramPreview();
    } else if (academyId) {
      invalidateUserAcademyLevelStep();
      invalidateAcademyLevels();
      invalidateAcademyLevelStep();
    }
    if (stepType === AcademyStepType.LxpSkillRating) {
      notify.skillRatingSuccess();
    } else {
      notify.fileUploadSuccess();
    }
  };

  const {chunkUpload, uploadProgress} = useChunkUpload({
    query: getUploadProjectSubmissionRm,
    customizableLearningCategory,
    onComplete: handleSubmitProjectSuccess,
  });

  const fileAttachments = attachments?.filter(
    ({type}) => type !== AttachmentTypeString.VideoString
  );

  const openUpload = useOpenUpload(customizableLearningCategory);
  const openAttachmentUpload = useOpenAttachmentUpload(
    customizableLearningCategory
  );

  useCustomProgramContentAttachmentUrlQuery(
    {
      attachmentId: fileAttachments?.[0]?.id,
    },
    {
      enabled: enableFetchAttachmentUrl,
      onSuccess: (url) => {
        if (!!url) {
          window.open(url, '_blank').focus();
          setEnableFetchAttachmentUrl(false);
        }
      },
    }
  );

  const {
    data: customProgramData,
    refetch: refetchCustomProgramPreview,
    invalidateExact: invalidateCustomProgramPreview,
  } = useCustomProgramPreviewQuery(
    {
      customProgramId,
      queryParams: {
        includeProgress: true,
      },
    },
    {
      enabled: false,
    }
  );

  const addToPlanMutation = useAddItemMutation();

  const {
    data: academyDiscussionMessages,
    invalidateExact: invalidateAcademyDiscussionMessages,
  } = useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesQuery(
    {
      academyId,
      academyLevelId: levelId,
      academyStepId: id,
      discussionId,
    },
    {enabled: !!discussionId && isAcademy}
  );
  const {
    data: customProgramDiscussionMessages,
    invalidateExact: invalidateCustomProgramDiscussionMessages,
  } = useCustomProgramsSectionsContentsDiscussionsMessagesQuery(
    {
      customProgramId,
      sectionId: levelId,
      contentId: id,
      discussionId,
      queryParams: {
        size: 100,
      },
    },
    {enabled: !!discussionId && !isAcademy}
  );

  const discussionMessages = isAcademy
    ? academyDiscussionMessages
    : customProgramDiscussionMessages;
  const invalidateDiscussionMessages = isAcademy
    ? invalidateAcademyDiscussionMessages
    : invalidateCustomProgramDiscussionMessages;

  const submitProject = useSubmitProjectMutation({
    onSuccess: handleSubmitProjectSuccess,
  });

  // Academy level step discussion messages
  const academiesAcademyLevelsAcademyStepsDiscussionsMessagesPost =
    useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation({
      onSuccess: handleSubmitProjectSuccess,
    });
  const academiesAcademyLevelsAcademyStepsDiscussionsMessagesPut =
    useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation({
      onSuccess: handleSubmitProjectSuccess,
    });
  const academiesAcademyLevelsAcademyStepsDiscussionsMessagesDelete =
    useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation({
      onSuccess: handleSubmitProjectSuccess,
    });

  // Custom Program level step discussion messages
  const customProgramSectionsContentDiscussionsMessagesPost =
    useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation({
      onSuccess: handleSubmitProjectSuccess,
    });
  const customProgramSectionsContentDiscussionsMessagesPut =
    useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation({
      onSuccess: handleSubmitProjectSuccess,
    });
  const customProgramSectionsContentDiscussionsMessagesDelete =
    useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation({
      onSuccess: handleSubmitProjectSuccess,
    });

  const formattedDescription = formatUrls(description);

  // Get first attachment preferring videos
  const attachment: AttachmentVM | undefined = (() => {
    if (attachments?.length) {
      return attachments.sort((a, _) =>
        a.type === AttachmentTypeString.VideoString ? -1 : 1
      )[0];
    } else {
      return undefined;
    }
  })();

  const eventHasPassed = parseToLocalMoment(endDateUtc)?.isBefore(moment());
  const formattedDueDate = parseToLocalMoment(dueDate)?.format(
    DATE_FORMAT.MONTH_LONG_DAY_YEAR
  );
  const formattedDueTime = parseToLocalMoment(dueDate)?.format(
    DATE_FORMAT.TIME_12_HR
  );
  const updateTextProjectSubmission = useUserPlanSubmitProjectContentMutation({
    onSuccess: handleSubmitProjectSuccess,
  });
  const updateLinkProjectSubmission =
    useUserPlanSubmitProjectContentLinkMutation({
      onSuccess: handleSubmitProjectSuccess,
    });
  const renderSubTitle = () => {
    switch (contentType) {
      case ContentType.Project:
      case ContentType.Discussion:
      case ContentType.Text:
        return !!dueDate ? (
          <DueDateContainer>
            <ContentDueDateStatus completed={completed} dueDateUtc={dueDate} />
            <DueDate>
              {formattedDueDate} • {formattedDueTime}{' '}
              {localTimeZone.abbreviated}
            </DueDate>
          </DueDateContainer>
        ) : null;
      case ContentType.Event:
        return (
          startDateUtc !== '' &&
          endDateUtc !== '' && (
            <EventContainer>
              <LiveEventTag eventHasPassed={eventHasPassed} />
              <EventTime
                endDateUtc={endDateUtc}
                startDateUtc={startDateUtc}
                timeZone={localTimeZone.abbreviated}
              />
            </EventContainer>
          )
        );
      default:
        return null;
    }
  };

  const showCompleteModalIfComplete = () => {
    if (
      customizableLearningCategory ===
      CustomizableLearningCategory.CustomProgram
    ) {
      refetchCustomProgramPreview().then(
        ({data: customProgram}: {data: CustomProgramPreviewVM}) => {
          if (customProgram?.progressPercentage === 100) {
            setShowCompleteModal(true);
          }
        }
      );
    }
  };

  const renderActionButton = () => {
    switch (contentType) {
      case ContentType.Text:
        return (
          <ContentCompleteButtonContainer
            isFullPage={isFullPage}
            academyId={academyId}
            isActivePlanItem={isActivePlanItem}
            completed={completed}
            contentId={id}
            customProgramId={customProgramId}
            customProgramSelected={programIsSelected}
            onContentCompleteStatusChange={onContentCompleteStatusChange}
            title={title}
            disabled={false}
          />
        );
      case ContentType.Event:
        return (
          <AcademyEventFooter
            academyId={academyId}
            isActivePlanItem={isActivePlanItem}
            skipProgram={skipProgram}
            unskipProgram={unskipProgram}
            showSkipProgramButton={showSkipProgramButton}
            showUnskipProgramButton={showUnskipProgramButton}
            status={status ?? DEFAULT_STATUS}
            eventHasPassed={eventHasPassed}
            rsvpStatus={rsvpStatus}
            contentId={id}
            eventLinkUrl={eventLink}
            isAcademy={isAcademy}
            onContentCompleteStatusChange={onContentCompleteStatusChange}
            levelId={levelId}
            calendarLinks={calendarLinks}
          />
        );
      default:
        return null;
    }
  };

  const refreshDataAfterResponseSubmission = () => {
    invalidateAcademyLevels();
    switch (customizableLearningCategory) {
      case CustomizableLearningCategory.CustomProgram:
        return invalidateCustomProgramPreview();
      case CustomizableLearningCategory.Academy:
        if (isAcademy) {
          invalidateUserAcademyLevelStep();
        }
    }
  };

  const callContentCompleteStatusChanged = () => {
    onContentCompleteStatusChange?.(id, ProgramContentStatus.Completed);
  };

  const joinCustomProgramIfNeeded = (): Promise<void> => {
    if (!customProgramData.selected) {
      return addToPlanMutation.mutateAsync({
        payload: {
          optionId: customProgramId,
          planItemType: PlanItemType.Program,
        },
      });
    }
  };
  const renderFooter = () => {
    switch (contentType) {
      case ContentType.SkillRating:
        return (
          <SkillAssessmentStepFooterContainer
            handleSkillRatingSubmission={() => {
              handleSubmitProjectSuccess();
            }}
            skillRatings={skillRatings}
            stepId={id}
            lxpRatingId={parseInt(title)}
          />
        );
      case ContentType.Project:
        return (
          <ProjectWrapper>
            <ProjectSubmissionContentCardFooterContainer
              title={title}
              isAcademy={isAcademy}
              isFullPage={isFullPage}
              peerSubmissionCount={peerSubmissionCount}
              showSkipProjectButton={showSkipProjectButton}
              allowPeerVisibility={allowPeerVisibility}
              stepId={id}
              academyOrProgramId={academyId || customProgramId}
              sectionId={levelId}
              skipProgram={skipProgram}
              unskipProgram={unskipProgram}
              contentId={id}
              status={status ?? DEFAULT_STATUS}
              onClickSubmittedFileLink={() => {
                if (!isActivePlanItem) return;
                openUpload(submittedFile?.id);
              }}
              completedOn={projectContentCompletedOn}
              submissionType={submissionType}
              submittedFile={submittedFile}
              submittedLink={submittedLink}
              submittedResponse={submittedResponse}
              showSubmittedResponse={isActivePlanItem}
              uploadProgress={uploadProgress}
              feedback={feedback}
              onClickSubmit={async (response: any, done: () => void) => {
                try {
                  if (!isActivePlanItem || !response) return;
                  switch (submissionType) {
                    case SubmissionType.Text:
                      if (submittedResponse) {
                        await updateTextProjectSubmission.mutateAsync({
                          payload: {
                            contentId: id,
                            customizableLearningCategory,
                            response,
                          },
                          pathVars: {
                            contentId: id,
                            customizableLearningCategory,
                          },
                        });
                      } else {
                        await submitProject.mutateAsync({
                          payload: {
                            contentId: id,
                            customizableLearningCategory,
                            response,
                          },
                        });
                      }
                      break;
                    case SubmissionType.Link:
                      if (submittedLink) {
                        await updateLinkProjectSubmission.mutateAsync({
                          payload: {
                            id: submittedLink.id,
                            title: response,
                            url: response,
                          },
                          pathVars: {
                            contentId: id,
                            customizableLearningCategory,
                          },
                        });
                      } else {
                        await submitProject.mutateAsync({
                          payload: {
                            contentId: id,
                            customizableLearningCategory,
                            response: '',
                            links: [{title: response, url: response}],
                          },
                        });
                      }
                      break;

                    case SubmissionType.FileUpload:
                      await chunkUpload(response, {contentId: id});
                  }
                  done?.();
                } catch (e) {
                  console.error(e);
                  done?.();
                }
              }}
            />
          </ProjectWrapper>
        );
      case ContentType.Discussion:
        return (
          <DiscussionStepFooter
            title={title}
            description={description}
            dueDate={dueDate}
            messages={discussionMessages?.data || []}
            messageCount={messageCount}
            showSkipButton={showSkipProjectButton}
            skipDiscussion={skipProgram}
            unskipDiscussion={unskipProgram}
            contentId={id}
            messagesPreview={messagesPreview}
            userMessages={userMessages}
            status={status ?? DEFAULT_STATUS}
            onClickDelete={(messageId: string) => {
              if (isAcademy) {
                academiesAcademyLevelsAcademyStepsDiscussionsMessagesDelete.mutate(
                  {
                    pathVars: {
                      academyId,
                      academyLevelId: levelId,
                      academyStepId: id,
                      discussionId,
                      messageId,
                    },
                  }
                );
              } else {
                customProgramSectionsContentDiscussionsMessagesDelete.mutate({
                  pathVars: {
                    customProgramId,
                    sectionId: levelId,
                    contentId: id,
                    discussionId,
                    messageId,
                  },
                });
              }
            }}
            onClickSubmit={async (response: any, messageId?: string) => {
              try {
                if (!isActivePlanItem || !response) return;
                if (messageId) {
                  if (isAcademy) {
                    academiesAcademyLevelsAcademyStepsDiscussionsMessagesPut.mutate(
                      {
                        payload: {content: response},
                        pathVars: {
                          academyId,
                          academyLevelId: levelId,
                          academyStepId: id,
                          discussionId,
                          messageId,
                        },
                      }
                    );
                  } else {
                    await joinCustomProgramIfNeeded();

                    customProgramSectionsContentDiscussionsMessagesPut.mutate({
                      payload: {content: response},
                      pathVars: {
                        customProgramId,
                        sectionId: levelId,
                        contentId: id,
                        discussionId,
                        messageId,
                      },
                    });
                  }
                } else {
                  if (isAcademy) {
                    academiesAcademyLevelsAcademyStepsDiscussionsMessagesPost.mutate(
                      {
                        payload: {content: response},
                        pathVars: {
                          academyId,
                          academyLevelId: levelId,
                          academyStepId: id,
                          discussionId,
                        },
                      }
                    );
                  } else {
                    await joinCustomProgramIfNeeded();

                    customProgramSectionsContentDiscussionsMessagesPost.mutate({
                      payload: {content: response},
                      pathVars: {
                        customProgramId,
                        sectionId: levelId,
                        contentId: id,
                        discussionId,
                      },
                    });
                  }
                }
              } catch (e) {
                console.error(e);
              }
            }}
          />
        );
      default:
        null;
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldBeInFocus) {
      setTimeout(() => {
        try {
          containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
          });
        } catch (error) {
          console.error(error);
        }
      }, 500);
    }
  }, [shouldBeInFocus]);
  return (
    <>
      <ScrollAnchor ref={containerRef} />
      <ContentCard
        isFullPage={isFullPage}
        attachment={attachment}
        contentType={contentType}
        dueDate={dueDate}
        formattedDescription={formattedDescription}
        handleClickFileAttachment={() =>
          openAttachmentUpload(fileAttachments?.[0]?.id)
        }
        link={links?.[0]}
        order={order}
        renderActionButton={renderActionButton}
        renderFooter={renderFooter}
        renderSubTitle={renderSubTitle}
        showIndexRule={showIndexRule}
        title={titleForSkillRating || title}
        contentId={id}
      />
      <ProgramCompletedModal
        visible={showCompleteModal}
        onClose={() => setShowCompleteModal(false)}
      />
    </>
  );
}
