import {UserDetailsVM} from '@generated/interfaces';
import {UrlPath} from './useGetUrlIncludesList';

/**
 * Returns true if the hash includes any of the paths in the hideSideNavWhenUrlIncludes list
 */
export const useUrlIsInHideNavList = (
  hideSideNavWhenUrlIncludes: UrlPath[],
  hash: string,
  user: UserDetailsVM
) => {
  const hashNormalized = '/' + hash.toLowerCase().replace('#/', '');
  const normalize = (str: string) => str.toLowerCase();

  // Exact match preferred, includes match as secondary
  const matchedRoute = (() => {
    const exactMatch = hideSideNavWhenUrlIncludes.find((pathObject) => {
      const enabled = pathObject.enabled?.(user) ?? true;
      return enabled && hashNormalized === normalize(pathObject.path);
    });

    // Return early with an exact match, if found
    if (exactMatch) {
      return exactMatch;
    }

    // If no exact match is found, find with an `includes` match.
    // Picks up sequential matches that might also have extra params in the URL
    return hideSideNavWhenUrlIncludes.find((pathObject) => {
      const enabled = pathObject.enabled?.(user) ?? true;
      return enabled && hashNormalized.includes(normalize(pathObject.path));
    });
  })();

  return {
    hashNormalized,
    urlIsInHideList: !!matchedRoute,
    alwaysHidden: matchedRoute?.alwaysHidden ?? false,
  };
};
