import {
  PreApprovalAmountBalanceVM,
  PreApprovalApplicationVM,
  ProgramProgressVM,
  UserPlanFinanceItemVM,
  UserPlanFinanceLearningBudgetOverviewVM,
  UserPlanItemCardVM,
  UserPlanProgramItemVM,
  UserPlanReimbursementItemVM,
  UserPlanResourceVM,
  UserPlanTemplate,
  UserPlanTimeItemVM,
} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {UserPlanTemplateSchema} from '../zodSchemas/UserPlanTemplateSchema.schema';
import {UserPlanItemCardVMSchema} from '../zodSchemas/UserPlanItemCardVMSchema.schema';
import {UserPlanProgramItemVMSchema} from '../zodSchemas/UserPlanProgramItemVMSchema.schema';
import {UserPlanTimeItemVMSchema} from '../zodSchemas/UserPlanTimeItemVMSchema.schema';
import {UserPlanFinanceItemVMSchema} from '../zodSchemas/UserPlanFinanceItemVMSchema.schema';
import {UserPlanFinanceLearningBudgetOverviewVMSchema} from '../zodSchemas/UserPlanFinanceLearningBudgetOverviewVMSchema.schema';
import {ProgramProgressVMSchema} from '../zodSchemas/ProgramProgressVMSchema.schema';
import {PreApprovalApplicationVMSchema} from '../zodSchemas/PreApprovalApplicationVMSchema.schema';
import {PreApprovalAmountBalanceVMSchema} from '../zodSchemas/PreApprovalAmountBalanceVMSchema.schema';
import {UserPlanResourceVMSchema} from '../zodSchemas/UserPlanResourceVMSchema.schema';
import {UserPlanReimbursementItemVMSchema} from '../zodSchemas/UserPlanReimbursementItemVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/UserPlan/UserPlanController.cs

/*
|--------------------------------------------------------------------------
|  do-programs-require-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDoProgramsRequireApprovalQuery} from '@generated/hooks';
 *
 *  const {
 *    data: doProgramsRequireApproval,
 *    isLoading: isDoProgramsRequireApprovalLoading,
 *    invalidateExact: invalidateDoProgramsRequireApproval,
 *  } = useDoProgramsRequireApprovalQuery(null)
 *
 */

export const useDoProgramsRequireApprovalQuery = queryHookFactory<
  null,
  boolean // return type
>(
  'useDoProgramsRequireApprovalQuery',
  () => `user-plan/do-programs-require-approval`,
  {responseSchema: z.boolean()}
);

// CACHED
export const useDoProgramsRequireApprovalQueryCached = queryHookFactory<
  null,
  boolean // return type
>(
  'useDoProgramsRequireApprovalQuery',
  () => `user-plan/do-programs-require-approval`,
  {responseSchema: z.boolean()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-finance-items 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useFinanceItemsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: financeItems,
 *    isLoading: isFinanceItemsLoading,
 *    invalidateExact: invalidateFinanceItems,
 *  } = useFinanceItemsQuery(null)
 *
 */

export const useFinanceItemsQuery = queryHookFactory<
  null,
  UserPlanFinanceItemVM[] // return type
>('useFinanceItemsQuery', () => `user-plan/get-finance-items`, {
  responseSchema: z.array(UserPlanFinanceItemVMSchema),
});

// CACHED
export const useFinanceItemsQueryCached = queryHookFactory<
  null,
  UserPlanFinanceItemVM[] // return type
>(
  'useFinanceItemsQuery',
  () => `user-plan/get-finance-items`,
  {responseSchema: z.array(UserPlanFinanceItemVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-finance-learning-budgets 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useFinanceLearningBudgetsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: financeLearningBuds,
 *    isLoading: isFinanceLearningBudsLoading,
 *    invalidateExact: invalidateFinanceLearningBuds,
 *  } = useFinanceLearningBudgetsQuery(null)
 *
 */

export const useFinanceLearningBudgetsQuery = queryHookFactory<
  null,
  UserPlanFinanceLearningBudgetOverviewVM // return type
>(
  'useFinanceLearningBudgetsQuery',
  () => `user-plan/get-finance-learning-budgets`,
  {responseSchema: UserPlanFinanceLearningBudgetOverviewVMSchema}
);

// CACHED
export const useFinanceLearningBudgetsQueryCached = queryHookFactory<
  null,
  UserPlanFinanceLearningBudgetOverviewVM // return type
>(
  'useFinanceLearningBudgetsQuery',
  () => `user-plan/get-finance-learning-budgets`,
  {responseSchema: UserPlanFinanceLearningBudgetOverviewVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-item/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useItemQuery} from '@generated/hooks';
 *
 *  const {
 *    data: item,
 *    isLoading: isItemLoading,
 *    invalidateExact: invalidateItem,
 *  } = useItemQuery({
 *    id
 *  })
 *
 */

export const useItemQuery = queryHookFactory<
  {id: number | string}, // path variables
  UserPlanItemCardVM // return type
>('useItemQuery', ({id}) => `user-plan/get-item/${id}`, {
  responseSchema: UserPlanItemCardVMSchema,
});

// CACHED
export const useItemQueryCached = queryHookFactory<
  {id: number | string}, // path variables
  UserPlanItemCardVM // return type
>(
  'useItemQuery',
  ({id}) => `user-plan/get-item/${id}`,
  {responseSchema: UserPlanItemCardVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-item-pre-approval-application 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useItemPreApprovalApplicationQuery} from '@generated/hooks';
 *
 *  const {
 *    data: itemPreApprovalApplication,
 *    isLoading: isItemPreApprovalApplicationLoading,
 *    invalidateExact: invalidateItemPreApprovalApplication,
 *  } = useItemPreApprovalApplicationQuery(null)
 *
 */

export const useItemPreApprovalApplicationQuery = queryHookFactory<
  null,
  PreApprovalApplicationVM // return type
>(
  'useItemPreApprovalApplicationQuery',
  () => `user-plan/get-item-pre-approval-application`,
  {responseSchema: PreApprovalApplicationVMSchema}
);

// CACHED
export const useItemPreApprovalApplicationQueryCached = queryHookFactory<
  null,
  PreApprovalApplicationVM // return type
>(
  'useItemPreApprovalApplicationQuery',
  () => `user-plan/get-item-pre-approval-application`,
  {responseSchema: PreApprovalApplicationVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-plan 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePlanQuery} from '@generated/hooks';
 *
 *  const {
 *    data: plan,
 *    isLoading: isPlanLoading,
 *    invalidateExact: invalidatePlan,
 *  } = usePlanQuery(null)
 *
 */

export const usePlanQuery = queryHookFactory<
  null,
  UserPlanTemplate // return type
>('usePlanQuery', () => `user-plan/get-plan`, {
  responseSchema: UserPlanTemplateSchema,
});

// CACHED
export const usePlanQueryCached = queryHookFactory<
  null,
  UserPlanTemplate // return type
>(
  'usePlanQuery',
  () => `user-plan/get-plan`,
  {responseSchema: UserPlanTemplateSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-plb-balance 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePlbBalanceQuery} from '@generated/hooks';
 *
 *  const {
 *    data: plbBalance,
 *    isLoading: isPlbBalanceLoading,
 *    invalidateExact: invalidatePlbBalance,
 *  } = usePlbBalanceQuery(null)
 *
 */

export const usePlbBalanceQuery = queryHookFactory<
  null,
  PreApprovalAmountBalanceVM // return type
>('usePlbBalanceQuery', () => `user-plan/get-plb-balance`, {
  responseSchema: PreApprovalAmountBalanceVMSchema,
});

// CACHED
export const usePlbBalanceQueryCached = queryHookFactory<
  null,
  PreApprovalAmountBalanceVM // return type
>(
  'usePlbBalanceQuery',
  () => `user-plan/get-plb-balance`,
  {responseSchema: PreApprovalAmountBalanceVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-pre-approval-application 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePreApprovalApplicationQuery} from '@generated/hooks';
 *
 *  const {
 *    data: preApprovalApplication,
 *    isLoading: isPreApprovalApplicationLoading,
 *    invalidateExact: invalidatePreApprovalApplication,
 *  } = usePreApprovalApplicationQuery(null)
 *
 */

export const usePreApprovalApplicationQuery = queryHookFactory<
  null,
  PreApprovalApplicationVM // return type
>(
  'usePreApprovalApplicationQuery',
  () => `user-plan/get-pre-approval-application`,
  {responseSchema: PreApprovalApplicationVMSchema}
);

// CACHED
export const usePreApprovalApplicationQueryCached = queryHookFactory<
  null,
  PreApprovalApplicationVM // return type
>(
  'usePreApprovalApplicationQuery',
  () => `user-plan/get-pre-approval-application`,
  {responseSchema: PreApprovalApplicationVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-preapproved-plan-items 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePreapprovedPlanItemsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: preapprovedPlanItems,
 *    isLoading: isPreapprovedPlanItemsLoading,
 *    invalidateExact: invalidatePreapprovedPlanItems,
 *  } = usePreapprovedPlanItemsQuery(null)
 *
 */

export const usePreapprovedPlanItemsQuery = queryHookFactory<
  null,
  UserPlanReimbursementItemVM[] // return type
>(
  'usePreapprovedPlanItemsQuery',
  () => `user-plan/get-preapproved-plan-items`,
  {responseSchema: z.array(UserPlanReimbursementItemVMSchema)}
);

// CACHED
export const usePreapprovedPlanItemsQueryCached = queryHookFactory<
  null,
  UserPlanReimbursementItemVM[] // return type
>(
  'usePreapprovedPlanItemsQuery',
  () => `user-plan/get-preapproved-plan-items`,
  {responseSchema: z.array(UserPlanReimbursementItemVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-program-items 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramItemsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programItems,
 *    isLoading: isProgramItemsLoading,
 *    invalidateExact: invalidateProgramItems,
 *  } = useProgramItemsQuery(null)
 *
 */

export const useProgramItemsQuery = queryHookFactory<
  null,
  UserPlanProgramItemVM[] // return type
>('useProgramItemsQuery', () => `user-plan/get-program-items`, {
  responseSchema: z.array(UserPlanProgramItemVMSchema),
});

// CACHED
export const useProgramItemsQueryCached = queryHookFactory<
  null,
  UserPlanProgramItemVM[] // return type
>(
  'useProgramItemsQuery',
  () => `user-plan/get-program-items`,
  {responseSchema: z.array(UserPlanProgramItemVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-program-progress/{planItemId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramProgressQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programProgress,
 *    isLoading: isProgramProgressLoading,
 *    invalidateExact: invalidateProgramProgress,
 *  } = useProgramProgressQuery({
 *    planItemId
 *  })
 *
 */

export const useProgramProgressQuery = queryHookFactory<
  {planItemId: number | string}, // path variables
  ProgramProgressVM[] // return type
>(
  'useProgramProgressQuery',
  ({planItemId}) => `user-plan/get-program-progress/${planItemId}`,
  {responseSchema: z.array(ProgramProgressVMSchema)}
);

// CACHED
export const useProgramProgressQueryCached = queryHookFactory<
  {planItemId: number | string}, // path variables
  ProgramProgressVM[] // return type
>(
  'useProgramProgressQuery',
  ({planItemId}) => `user-plan/get-program-progress/${planItemId}`,
  {responseSchema: z.array(ProgramProgressVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-resource-approval-application 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useResourceApprovalApplicationQuery} from '@generated/hooks';
 *
 *  const {
 *    data: resourceApprovalApplication,
 *    isLoading: isResourceApprovalApplicationLoading,
 *    invalidateExact: invalidateResourceApprovalApplication,
 *  } = useResourceApprovalApplicationQuery(null)
 *
 */

export const useResourceApprovalApplicationQuery = queryHookFactory<
  null,
  PreApprovalApplicationVM // return type
>(
  'useResourceApprovalApplicationQuery',
  () => `user-plan/get-resource-approval-application`,
  {responseSchema: PreApprovalApplicationVMSchema}
);

// CACHED
export const useResourceApprovalApplicationQueryCached = queryHookFactory<
  null,
  PreApprovalApplicationVM // return type
>(
  'useResourceApprovalApplicationQuery',
  () => `user-plan/get-resource-approval-application`,
  {responseSchema: PreApprovalApplicationVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-resource-items 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useResourceItemsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: resourceItems,
 *    isLoading: isResourceItemsLoading,
 *    invalidateExact: invalidateResourceItems,
 *  } = useResourceItemsQuery(null)
 *
 */

export const useResourceItemsQuery = queryHookFactory<
  null,
  UserPlanResourceVM[] // return type
>('useResourceItemsQuery', () => `user-plan/get-resource-items`, {
  responseSchema: z.array(UserPlanResourceVMSchema),
});

// CACHED
export const useResourceItemsQueryCached = queryHookFactory<
  null,
  UserPlanResourceVM[] // return type
>(
  'useResourceItemsQuery',
  () => `user-plan/get-resource-items`,
  {responseSchema: z.array(UserPlanResourceVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-time-items 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTimeItemsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: timeItems,
 *    isLoading: isTimeItemsLoading,
 *    invalidateExact: invalidateTimeItems,
 *  } = useTimeItemsQuery(null)
 *
 */

export const useTimeItemsQuery = queryHookFactory<
  null,
  UserPlanTimeItemVM[] // return type
>('useTimeItemsQuery', () => `user-plan/get-time-items`, {
  responseSchema: z.array(UserPlanTimeItemVMSchema),
});

// CACHED
export const useTimeItemsQueryCached = queryHookFactory<
  null,
  UserPlanTimeItemVM[] // return type
>(
  'useTimeItemsQuery',
  () => `user-plan/get-time-items`,
  {responseSchema: z.array(UserPlanTimeItemVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  has-resources-enabled 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useHasResourcesEnabledQuery} from '@generated/hooks';
 *
 *  const {
 *    data: hasResourcesEnabled,
 *    isLoading: isHasResourcesEnabledLoading,
 *    invalidateExact: invalidateHasResourcesEnabled,
 *  } = useHasResourcesEnabledQuery(null)
 *
 */

export const useHasResourcesEnabledQuery = queryHookFactory<
  null,
  boolean // return type
>('useHasResourcesEnabledQuery', () => `user-plan/has-resources-enabled`, {
  responseSchema: z.boolean(),
});

// CACHED
export const useHasResourcesEnabledQueryCached = queryHookFactory<
  null,
  boolean // return type
>(
  'useHasResourcesEnabledQuery',
  () => `user-plan/has-resources-enabled`,
  {responseSchema: z.boolean()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  lxp/organization/{organizationId}/finance-learning-budgets/{lxpUserId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useLxpOrganizationQuery} from '@generated/hooks';
 *
 *  const {
 *    data: lxpOrganization,
 *    isLoading: isLxpOrganizationLoading,
 *    invalidateExact: invalidateLxpOrganization,
 *  } = useLxpOrganizationQuery({
 *    lxpUserId,
 *    organizationId
 *  })
 *
 */

export const useLxpOrganizationQuery = queryHookFactory<
  {lxpUserId: number; organizationId: number}, // path variables
  UserPlanFinanceLearningBudgetOverviewVM // return type
>(
  'useLxpOrganizationQuery',
  ({organizationId, lxpUserId}) =>
    `user-plan/lxp/organization/${organizationId}/finance-learning-budgets/${lxpUserId}`,
  {responseSchema: UserPlanFinanceLearningBudgetOverviewVMSchema}
);

// CACHED
export const useLxpOrganizationQueryCached = queryHookFactory<
  {lxpUserId: number; organizationId: number}, // path variables
  UserPlanFinanceLearningBudgetOverviewVM // return type
>(
  'useLxpOrganizationQuery',
  ({organizationId, lxpUserId}) =>
    `user-plan/lxp/organization/${organizationId}/finance-learning-budgets/${lxpUserId}`,
  {responseSchema: UserPlanFinanceLearningBudgetOverviewVMSchema},
  {staleTime: 600000}
);
