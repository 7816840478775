import {useEffect, useState, FC} from 'react';
import {COLORS} from '@utils/constants';
import Select, {
  components,
  OptionProps,
  SingleValue,
  StylesConfig,
} from 'react-select';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {RatingOption} from './TemporaryConstants';
import {SkillRatingOption} from './SkillRatingOption';

interface WithRatingCountProps {
  ratingCount: number;
}

function withRatingCount<P extends OptionProps<RatingOption>>(
  Component: React.ComponentType<P & WithRatingCountProps>,
  ratingCount: number
): FC<P> {
  const WithRatingCount = (props: P) => {
    return <Component {...props} ratingCount={ratingCount} />;
  };

  const componentName = Component.displayName || Component.name || 'Component';
  WithRatingCount.displayName = `withRatingCount(${componentName})`;

  return WithRatingCount;
}

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const customStyles: StylesConfig<RatingOption, false> = {
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
  option: (provided, {isFocused}) => ({
    ...provided,
    overflow: 'visible',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: COLORS.Blue200,
    },
    '&:active': {
      backgroundColor: COLORS.Blue200,
    },
    ...(isFocused && {
      backgroundColor: COLORS.Blue200,
    }),
  }),
  menu: (provided) => ({
    ...provided,
    width: 'auto',
  }),
};

const StyledOption = ({
  ratingCount,
  ...props
}: OptionProps<RatingOption> & WithRatingCountProps) => {
  return (
    <components.Option {...props}>
      <SkillRatingOption
        ratingCount={ratingCount}
        isSelected={props.isSelected}
        option={props.data}
        isOpen={true}
      />
    </components.Option>
  );
};

interface SkillAssessmentStepFooterProps {
  options: RatingOption[];
  defaultValue?: RatingOption;
  value?: number;
  onSaveRating?: (value: number) => void;
  required?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
}

export function SkillAssessmentStepFooter({
  options,
  value,
  onSaveRating,
  loading,
  isDisabled,
}: SkillAssessmentStepFooterProps) {
  const [selectedRating, setSelectedRating] = useState<number | undefined>(
    undefined
  );

  const selectOptions = options.map((option) => ({
    value: option.value,
    label: option.label || '',
    ...option,
  }));

  const defaultValue = selectOptions.find((option) => option.value === value);
  const OptionWithRatingCount = withRatingCount(
    StyledOption,
    options.length || 0
  );
  useEffect(() => {
    if (value !== undefined) {
      setSelectedRating(value);
    }
  }, [value]);
  return (
    <Container>
      <Select
        isDisabled={isDisabled}
        styles={customStyles}
        options={selectOptions}
        value={selectOptions.find((option) => option.value === selectedRating)}
        onChange={(newValue: SingleValue<RatingOption>) => {
          setSelectedRating(newValue?.value);
        }}
        components={{Option: OptionWithRatingCount}}
        aria-label={i18n.t(k.RATING__SELECT_RATING)}
        placeholder={i18n.t(k.RATING__SELECT_RATING)}
        isSearchable={false}
      />
      {!isDisabled && (
        <LearnInButton
          style={{maxWidth: '150px'}}
          loading={loading}
          onClick={() => onSaveRating(selectedRating)}
          tag={ButtonTags.Primary}
          aria-label={i18n.t(k.RATING__SUBMIT_RATING)}>
          {loading
            ? i18n.t(k.RATING__SUBMITTING_RATING)
            : i18n.t(k.RATING__SUBMIT_RATING)}
        </LearnInButton>
      )}
    </Container>
  );
}
