import {i18n, k} from '@i18n/translate';
import {Skeleton, Typography} from 'antd';
import UserPlanCard from '@blocks/userPlanCard/UserPlanCard';
import {ProgramsCarouselSection} from '@components/reusable/Carousel/ProgramsCarouselSection';
import {isItemComplete, TItem} from '@utils/itemUtils';
import {PlanItemType} from '@generated/enums';
import {ProgramForCarousel} from '@models/serverModels';
import {ManageYourPlanTitle} from '@components/reusable/Text/Text.style';
import {UserPlanSectionTitle} from '@models/clientEnums';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {UserDetailsVM} from '@generated/interfaces';
import {useIntegratedMode} from '@hooks/integrated/useIntegratedMode';

/*
|--------------------------------------------------------------------------
| Util component
|--------------------------------------------------------------------------
*/

export const CategoryTitle = ({title}: {title: string}) => {
  return (
    <Typography.Title level={4} className="manage-plan-category-title">
      {title}
    </Typography.Title>
  );
};

/*
|--------------------------------------------------------------------------
| Program/Incentive Section Component
|--------------------------------------------------------------------------
*/

interface UserPlanSectionProps {
  data: TItem[];
  isDirectBilling?: boolean;
  isSuccess: boolean;
  planItemType: PlanItemType;
  programsForCarousel: ProgramForCarousel[];
  showCompleted: boolean;
  title: string;
  user: UserDetailsVM;
}

function UserPlanSection({
  data,
  isDirectBilling = false,
  isSuccess,
  planItemType,
  programsForCarousel,
  showCompleted,
  title,
  user,
}: UserPlanSectionProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isIntegratedMode = useIntegratedMode(user);

  // Render Loading Indicator
  if (!isSuccess || !data) return <Skeleton active />;

  // Render completed tab section
  // Only render sections that contain completed items
  if (showCompleted) {
    const completedItems = data.filter(isItemComplete);
    if (!!completedItems.length) {
      return (
        <>
          <ManageYourPlanTitle>{i18n.t(k.PROGRAM__PLURAL)}</ManageYourPlanTitle>
          {completedItems.map((item, index) => (
            <UserPlanCard
              key={index}
              planItemType={planItemType}
              showActions
              item={item}
            />
          ))}
        </>
      );
    }
    return null;
  }

  // Render In Progress tab sections
  // Render either incomplete items or a carousel of items to explore
  const incompleteItems = data.filter((item) => !isItemComplete(item));
  if (!!incompleteItems.length) {
    return (
      <>
        {title === UserPlanSectionTitle.PROGRAMS ? (
          <ManageYourPlanTitle>{i18n.t(k.PROGRAM__PLURAL)}</ManageYourPlanTitle>
        ) : (
          <CategoryTitle title={title} />
        )}
        {incompleteItems.reverse().map((item, index) => (
          <UserPlanCard
            key={index}
            planItemType={planItemType}
            showActions
            item={item}
          />
        ))}
      </>
    );
  }
  if (
    !!programsForCarousel?.length &&
    !isFeatureFlagOn.HideMarketplace &&
    !isFeatureFlagOn.LxpToCmFlow &&
    !isIntegratedMode
  ) {
    return (
      <>
        <CategoryTitle title={title} />
        <ProgramsCarouselSection
          isDirectBilling={isDirectBilling}
          programsForCarousel={programsForCarousel || []}
          type={planItemType}
        />
      </>
    );
  }
  return null;
}

export default UserPlanSection;
