import DesktopSideNav from '@blocks/appNav/DesktopSideNav';
import MobileAppHeaderWithMobileMenu from '@blocks/appNav/MobileAppHeaderWithMobileMenu';
import CardIssuerTerms from '@components/CardIssuerTerms';
import DatadogErrorBoundary from '@components/reusable/ErrorBoundary/DataDogErrorBoundary';
import Request from '@components/user/components/request';
import {RequestMenuButtonAndDrawerViewContainer} from '@components/user/components/request/RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.styled';
import {useApolloLayoutForAcm} from '@hooks/apollo';
import useGetEnabledIntegratedPrimaryRoutes from '@hooks/useGetEnabledIntegratedPrimaryRoutes';
import {Layout} from '@learnin-inc/apollo-react-cdk';
import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import {Theme} from '@utils/Theme';
import * as React from 'react';
import {useState} from 'react';
import {createHashRouter, RouterProvider, Outlet} from 'react-router-dom';
import styled from 'styled-components';
import LoadingSpinner from './elements/loadingSpinner/LoadingSpinner';
import useGetEnabledPrimaryRoutesShared from '@hooks/useGetEnabledPrimaryRoutesShared';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useIntegratedMode} from '@hooks/integrated/useIntegratedMode';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const MainContentContainer = styled.main<{fullWidth?: boolean}>`
  position: fixed;
  left: ${({fullWidth}) => (fullWidth ? 0 : '272px')};
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  background: ${COLORS.Neutral50};
  @media (max-width: ${Breakpoints.Desktop}px) {
    left: 0;
    top: 68px;
  }
`;

/*
|--------------------------------------------------------------------------
| Main Routes Component
|--------------------------------------------------------------------------
*/
interface PrimaryRoutesProps {
  flagsReady: boolean;
  hasAcmLayoutFlag: boolean;
  hasLxpAdminLayout: boolean;
  hidePrimaryNavigation: boolean;
  isLxpAdmin: boolean;
}

export const PrimaryRoutes = ({
  flagsReady,
  hasAcmLayoutFlag,
  hasLxpAdminLayout,
  hidePrimaryNavigation,
  isLxpAdmin,
}: PrimaryRoutesProps) => {
  const [{showApolloLayout, layoutAspect}, {isAdminPath, isDirectBilling}] =
    useApolloLayoutForAcm(isLxpAdmin);
  const hasApolloFlags = hasAcmLayoutFlag || hasLxpAdminLayout;

  const [drawerVisible, setDrawerVisible] = useState(false);

  return !flagsReady ? (
    <LoadingSpinner />
  ) : showApolloLayout && hasApolloFlags ? (
    !hidePrimaryNavigation ? (
      <Layout
        configuration={layoutAspect}
        onAddContent={() => setDrawerVisible(true)}>
        <div className="tw-grid-apollo tw-mt-10">
          <div className="tw-col-span-full">
            <CardIssuerTerms />
            <Outlet />
            {!isAdminPath && !isDirectBilling && (
              <RequestMenuButtonAndDrawerViewContainer>
                <Request
                  showButton={false}
                  drawerVisible={drawerVisible}
                  setDrawerVisible={setDrawerVisible}
                />
              </RequestMenuButtonAndDrawerViewContainer>
            )}
          </div>
        </div>
      </Layout>
    ) : (
      <>
        <CardIssuerTerms />
        <Outlet />
      </>
    )
  ) : (
    // Legacy Layout
    <>
      <CardIssuerTerms />
      {!hidePrimaryNavigation && (
        <>
          <DesktopSideNav /> <MobileAppHeaderWithMobileMenu />
        </>
      )}
      <MainContentContainer
        id="main-content"
        tabIndex="-1"
        fullWidth={hidePrimaryNavigation}>
        <Outlet />
      </MainContentContainer>
    </>
  );
};

export const PrimaryRoutesContent = () => {
  const {user} = useAuth();
  const {isManager, isAdmin} = user;
  const isIntegratedMode = useIntegratedMode(user);

  // Integrated Routes
  const integratedRoutes = useGetEnabledIntegratedPrimaryRoutes({
    isAdmin,
    isManager,
    isIntegratedMode,
  });

  // Shared Routes
  const sharedPrimaryRoutes = useGetEnabledPrimaryRoutesShared({
    isManager,
    isAdmin,
  });

  const router = createHashRouter(
    isIntegratedMode ? integratedRoutes : sharedPrimaryRoutes
  );

  return (
    <DatadogErrorBoundary>
      <Theme>
        <React.Suspense fallback={<LoadingSpinner />}>
          <RouterProvider router={router} />
        </React.Suspense>
      </Theme>
    </DatadogErrorBoundary>
  );
};

export default PrimaryRoutes;
