import {
  AcademyUserVM,
  GetUsersOrGroupsResponse,
  GroupSearchResultVM,
  UserSearchResultVM,
} from '@generated/interfaces';
import {api} from '@store/api';
import {getCompanyUsersAndGroups, getMemberAddStatus} from '@utils/ClientPaths';
import {CompanyMembersForAdding} from './types';
import {i18n, k} from '@i18n/translate';

// Type guard to check existence user and group properties
const isGroup = (
  item: UserSearchResultVM | GroupSearchResultVM
): item is GroupSearchResultVM => 'privacyLevel' in item && !!item.privacyLevel;

const isEmail = (
  item: UserSearchResultVM | GroupSearchResultVM
): item is UserSearchResultVM => 'email' in item && !!item.email;

// Get users and groups for the company and filter out existing users
export const getUserAndGroupOptions = async (
  name: string,
  users: AcademyUserVM[],
  companyId: number,
  canShowEmail: boolean
): Promise<CompanyMembersForAdding[]> => {
  const response = await api.get<GetUsersOrGroupsResponse>(
    getCompanyUsersAndGroups(companyId, {name})
  );

  // Create a Set of existing emails for lookup
  const existingEmails = new Set(users.map((user) => user.email));

  const mergedOptions: (UserSearchResultVM | GroupSearchResultVM)[] = [
    ...(response.data.users ?? []),
    ...(response.data.groups ?? []),
  ];

  const options = mergedOptions
    // Do not include existing Academy users
    .filter((item) => (isEmail(item) ? !existingEmails.has(item.email) : true))
    .map((item) => ({
      label: item.name,
      value: `${isGroup(item) ? 'group' : 'user'}-${item.id}`,
      email: isEmail(item) ? item.email : null,
      canShowEmployeeEmail: canShowEmail,
      memberCount: isGroup(item) ? item.memberCount : null,
      privacyLevel: isGroup(item) ? item.privacyLevel : null,
      isGroup: isGroup(item),
    }));

  return options;
};

// Parse user and group ids from selected values string array
interface CompanyGroupIds {
  userCompanyIds: number[];
  groupIds: number[];
}
export const getMemberIds = (
  selectedMembersForAdding: {value: string}[]
): CompanyGroupIds => {
  const userCompanyIds: number[] = [];
  const groupIds: number[] = [];

  selectedMembersForAdding.forEach((member) => {
    const [prefix, id] = member.value.split('-');

    if (!id || isNaN(parseInt(id))) {
      throw new Error(`Invalid format: ${member.value}`);
    }

    const parsedId = parseInt(id);

    if (prefix === 'user') {
      userCompanyIds.push(parsedId);
    } else if (prefix === 'group') {
      groupIds.push(parsedId);
    }
  });

  return {userCompanyIds, groupIds};
};

export const customAsyncSelectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Adjust the z-index value as needed
  }),
};

// Get singular or pluralized member count text
export const getMemberCountText = (count: number): string => {
  return count === 1 ? i18n.t(k.GENERIC__MEMBER) : i18n.t(k.GENERIC__MEMBERS);
};
