// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserPlanContentLxpSkillRating.cs

import {z} from 'zod';

export const UserPlanContentLxpSkillRatingSchema = z.object({
  contentId: z.number(),
  lxpScaleId: z.number().nullable().optional(),
  lxpSkillRatingId: z.number().nullable().optional(),
  rating: z.number(),
  scale: z.number(),
  skillTagId: z.number(),
});
