import {UserDetailsVM} from '@generated/interfaces';
import {useIsLxpAndAcademiesUser} from './useIsLxpAndAcademiesUser';
import {useIsLxpAdmin} from './useIsLxpAdmin';

export const useIntegratedMode = (user: UserDetailsVM): boolean => {
  const lxpAdmin = useIsLxpAdmin();
  const lxpAndAcademies = useIsLxpAndAcademiesUser(
    user?.isAcademiesIntegratedExperienceDisabled
  );
  return lxpAdmin || lxpAndAcademies;
};
