import {i18n, k} from '@i18n/translate';
import {message} from 'antd';
import NotificationContent from './NotificationContent';
import {CUSTOM_CONTENT_DESCRIPTION_MAX_LENGTH} from '@utils/constants';
import {getViewPlanLinkUrl} from '@utils/ClientPaths';

export const openMessage = (
  type: 'success' | 'warning' | 'error',
  content: React.ReactNode,
  duration?: any
) => {
  message[type]({
    content,
    duration: duration || 3,
    style: {
      marginTop: '85vh',
    },
  });
};

export const notify = {
  maxFileUploadSizeExceededError: ({
    fileSize,
    maxSize,
  }: {
    fileSize: string;
    maxSize: string;
  }) =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__UPLOAD__MAX_SIZE_EXCEEDED__FORMAT, {
          fileSize,
          maxSize,
        })
      )
    ),
  requestReimbursementSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.REIMBURSEMENT__SUBMITTED_SUCCESS))
    ),
  addToPlanSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__PLAN__ADDED__SUCCESS),
        getViewPlanLinkUrl(),
        i18n.t(k.CTA__VIEW).toLocaleUpperCase()
      ),
      10
    ),
  addToPlanError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PLAN__ADDED__ERROR))
    ),
  addEventSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__EVENT__ADDED__SUCCESS))
    ),
  addEventError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__EVENT__ADDED__ERROR))
    ),
  connectCalendarError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CALENDAR__CONNECTED__ERROR))
    ),

  disconnectCalendarSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__CALENDAR__DISCONNECTED__SUCCESS)
      )
    ),
  disconnectCalendarError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CALENDAR__DISCONNECTED__ERROR))
    ),
  updateEventSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__EVENT__UPDATED__SUCCESS))
    ),
  updateEventError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__EVENT__UPDATED__ERROR))
    ),
  deleteFromPlanSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PLAN__REMOVED_FROM__SUCCESS))
    ),
  deleteFromPlanError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PLAN__REMOVED_FROM__ERROR))
    ),
  deleteFromPlanInProgressError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__IN_PROGRESS_REMOVAL__ERROR))
    ),
  applyPlanPreApprovedSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__YOURE_PREAPPROVED))
    ),
  applyPlanPreApprovedSaveSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__SAVED___SUCCESS))
    ),
  applyPlanSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__APPROVAL__REQUESTED___SUCCESS))
    ),
  applyPlanError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PLAN__APPLY___ERROR))
    ),
  updateUserProgramAppliedSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PLAN__APPLY___SUCCESS))
    ),
  updateUserProgramAdmittedSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__APPROVED___SUCCESS))
    ),
  updateUserProgramAppliedError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__APPLIED___ERROR))
    ),
  updateUserProgramAdmittedError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__APPROVED___ERROR))
    ),
  startDateAfterEndDate: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__DATE__START_END___ERROR))
    ),
  startTimeAfterEndTime: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__TIME__START_END___ERROR))
    ),
  settingsUpdateSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__SETTINGS__UPDATED___SUCCESS))
    ),
  settingsUpdateError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__SETTINGS__UPDATED___ERROR))
    ),
  passwordUpdateError: (errorMessage: any) =>
    openMessage('error', NotificationContent(errorMessage)),
  settingsUpdateFormError: () =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__SETTINGS__UPDATED__FORM___ERROR)
      )
    ),
  newProgramAddError: () =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__PROGRAM__ADDED_TO_PLAN___ERROR)
      )
    ),
  newProgramAddedByAdminSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__ADDED___SUCCESS))
    ),
  updateProgramAddedByAdminSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__UPDATED___SUCCESS))
    ),
  updateProgramAddedByAdminError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__UPDATED___ERROR))
    ),

  approveSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__REQUEST__APPROVED___SUCCESS))
    ),
  rejectSuccessful: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__REQUEST__REJECTED___SUCCESS))
    ),
  approvalUpdateError: () =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__REQUEST__APPROVAL__UPDATED___ERROR)
      )
    ),
  submitTimeForApprovalSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__REQUEST__TIME__APPROVAL__SUCCESS)
      )
    ),
  submitTimeForApprovalError: () =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__REQUEST__TIME__APPROVAL__ERROR)
      )
    ),
  deleteTimeIncentiveSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__INCENTIVE__TIME__DELETED__SUCCESS)
      )
    ),
  deleteTimeIncentiveFailure: () =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__INCENTIVE__TIME__DELETED__ERROR)
      )
    ),
  deleteMoneyIncentiveSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__INCENTIVE__FINANCE__DELETED__SUCCESS)
      )
    ),
  deleteMoneyIncentiveFailure: () =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__INCENTIVE__FINANCE__DELETED__ERROR)
      )
    ),
  markFeaturedSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__FEATURE__MARKED__SUCCESS))
    ),
  markRemovedFeaturedSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__FEATURE__UNMARKED__SUCCESS))
    ),
  markFeaturedFailure: (program: any) =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__FEATURE__MARKED__ERROR__FORMAT, {program})
      )
    ),
  markAvailableSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__AVAILABLE__MARKED__SUCCESS))
    ),
  markUnavailableSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__UNAVAILABLE__MARKED__SUCCESS))
    ),
  markAvailabilityFailure: (program: any) =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__UNAVAILABLE__UPDATE__ERROR__FORMAT, {program})
      )
    ),
  recommendToUsersSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__NOMINATED__SUCCESS))
    ),
  recommendToUsersFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__RECOMMEND_EMPLOYEE__ERROR))
    ),
  emptyReportSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__DATA_EXPORTED))
    ),
  draftInitiativeChangesSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__INITIATIVE__DRAFT__SUCCESS))
    ),
  publishInitiativeChangesSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__INITIATIVE__PUBLISHED__SUCCESS)
      )
    ),
  publishInitiativeChangesFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__INITIATIVE__PUBLISHED__ERROR))
    ),
  creditCardNumberCopied: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__CREDIT_CARD__COPIED__SUCCESS))
    ),
  CVVCopied: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__CREDIT_CARD__CVV__COPIED__SUCCESS)
      )
    ),
  removeProgramNominationSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__NOMINATION_REMOVED__SUCCESS))
    ),
  removeProgramNominationFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__NOMINATION_REMOVED__ERROR))
    ),
  addCompanyBankAccountSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__BANK_ACCOUNT__ADDED__SUCCESS))
    ),
  addCompanyBankAccountFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__BANK_ACCOUNT__ADDED__ERROR))
    ),
  deleteCompanyBankAccountSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__BANK_ACCOUNT__REMOVED__SUCCESS)
      )
    ),
  deleteCompanyBankAccountFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__BANK_ACCOUNT__REMOVED__ERROR))
    ),
  topUpBalanceSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__FUNDS__ADDED__SUCCESS))
    ),
  topUpBalanceFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__FUNDS__ADDED__ERROR))
    ),
  testFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__TEST__ERROR))
    ),
  addProgramReviewSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__REVIEW__ADDED__SUCCESS))
    ),
  updateProgramReviewSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__REVIEW__UPDATED__SUCCESS))
    ),
  deleteProgramReviewSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__REVIEW__DELETED__SUCCESS))
    ),
  addProgramReviewError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__REVIEW__ADDED__ERROR))
    ),
  uploadContentAttachmentSuccess: (fileName: any) =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__FILE__UPDATED__SUCCESS__FORMAT, {fileName})
      )
    ),
  uploadContentAttachmentTooLargeError: (fileName: any) =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__FILE__UPLOAD__ERROR__TOO_LARGE__FORMAT, {
          fileName,
        })
      )
    ),
  uploadContentAttachmentIsInvalid: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__FILE__UPLOAD__ERROR__INVALID))
    ),
  uploadContentAttachmentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__FILE__UPLOAD__ERROR))
    ),
  uploadContentAttachmentCancellation: () =>
    openMessage(
      'warning',
      NotificationContent(i18n.t(k.NOTIFICATION__FILE__UPLOAD__CANCELLATION))
    ),
  updateProgramReviewError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__REVIEW__UPDATED__ERROR))
    ),
  deleteProgramReviewError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__REVIEW__DELETED__ERROR))
    ),
  restoreCardError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CARD__RESTORED__ERROR))
    ),
  updateBalanceSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__BALANCE__UPDATED__SUCCESS))
    ),
  increaseBalanceFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__FUNDS__ADDED__ERROR))
    ),
  devSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__SUCCESS))
    ),
  devFailure: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__ERROR_LOGGED))
    ),
  addCustomProgramSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__ADDED___SUCCESS))
    ),
  updateCustomProgramSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__CHANGES__SAVED__SUCCESS))
    ),
  updateAcademySuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__CHANGES__SAVED__SUCCESS))
    ),
  updateCustomProgramEmailMessageSuccess: (emailNotificationType?: string) =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__UPDATED__SUCCESS__FORMAT, {
          emailNotificationType:
            emailNotificationType ??
            i18n.t(k.GENERIC__EMAIL).toLocaleLowerCase(),
        })
      )
    ),
  updateCustomProgramEmailMessageError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__UPDATE__ERROR))
    ),
  deleteCustomProgramCoverImageSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__IMAGE__DELETED__SUCCESS))
    ),
  deleteCustomProgramCoverImageError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__IMAGE__DELETED__ERROR))
    ),
  deleteCustomProgramContentAttachmentSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__ATTACHMENT__DELETED__SUCCESS))
    ),
  deleteCustomProgramContentAttachmentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__ATTACHMENT__DELETED__ERROR))
    ),
  deleteCustomProgramSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__DELETED__SUCCESS))
    ),
  deleteCustomProgramError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__DELETED__ERROR))
    ),
  duplicateCustomStepContentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__DUPLICATED__ERROR))
    ),
  duplicateCustomProgramError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__DUPLICATED__ERROR))
    ),
  duplicateCustomProgramSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__PROGRAM__CUSTOM__DUPLICATED__SUCCESS)
      )
    ),
  deleteAcademyUserPermissionSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__DELETED__SUCCESS))
    ),
  deleteAcademyUserPermissionError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__DELETED__ERROR))
    ),
  addAcademyUserPermissionsSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__ADDED__SUCCESS))
    ),
  addAcademyUserPermissionsError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__ADDED__ERROR))
    ),
  updateAcademyUserPermissionSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__UPDATED__SUCCESS))
    ),
  updateAcademyUserPermissionError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__UPDATED__ERROR))
    ),
  addCustomProgramUserPermissionsSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__UPDATED__SUCCESS))
    ),
  addCustomProgramUserPermissionsError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__UPDATED__ERROR))
    ),
  deleteCustomProgramUserPermissionSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__DELETED__SUCCESS))
    ),
  deleteCustomProgramUserPermissionError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__DELETED__ERROR))
    ),
  updateCustomProgramUserPermissionSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__UPDATED__SUCCESS))
    ),
  updateCustomProgramUserPermissionError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PERMISSION__UPDATED__ERROR))
    ),
  saveProgramRequired: () =>
    openMessage(
      'warning',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__PLEASE_SAVE))
    ),
  // Custom Program Section
  addCustomProgramSectionSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__SECTION__ADDED__SUCCESS))
    ),
  deleteCustomProgramSectionSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__SECTION__DELETED__SUCCESS))
    ),
  // Custom Program Content
  addCustomProgramContentLengthError: () =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT, {
          item: i18n.t(k.GENERIC__DESCRIPTION),
          max: CUSTOM_CONTENT_DESCRIPTION_MAX_LENGTH,
        })
      )
    ),
  addCustomProgramContentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CONTENT__ADDED__ERROR))
    ),
  updateCustomProgramContentSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__CONTENT__ADDED__SUCCESS))
    ),
  updateCustomProgramContentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CONTENT__UPDATED__ERROR))
    ),
  addCustomProgramDateSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__DATE__ADDED__SUCCESS))
    ),
  addCustomProgramDateError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__DATE__ADDED__ERROR))
    ),
  reorderCustomProgramContentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CONTENT__REORDERED__ERROR))
    ),
  moveCustomProgramContentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CONTENT__MOVED__ERROR))
    ),
  updateAcademyEventRsvpError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__RSVP__UPDATED__ERROR))
    ),
  updateAcademyStepCompletionError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__UPDATED__ERROR))
    ),
  deleteAcademyStepError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__DELETED__ERROR))
    ),
  deleteCustomProgramContentError: (contentTypeString?: string) =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__CONTENT__DELETED__ERROR__FORMAT, {
          contentType: contentTypeString || i18n.t(k.CONTENT),
        })
      )
    ),
  deleteCustomProgramContentSuccess: (contentTypeString?: string) =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__CONTENT__DELETED__SUCCESS__FORMAT, {
          contentType: contentTypeString || i18n.t(k.CONTENT),
        })
      )
    ),
  duplicateCustomProgramContentError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__CONTENT__DUPLICATED__ERROR))
    ),
  programPublishedSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__PUBLISHED__SUCCESS))
    ),
  programPublishedError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__PUBLISHED__ERROR))
    ),
  attachmentOpenedError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__ATTACHMENT__OPENED__ERROR))
    ),
  // dev controller
  addUserSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__USER__ADDED__SUCCESS))
    ),
  addUserError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__USER__ADDED__ERROR))
    ),
  addCompanySuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__COMPANY__ADDED__SUCCESS))
    ),
  addCompanyError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__COMPANY__ADDED__FAILED))
    ),
  // Generic Notifications
  tableDataLoadingError: (tableName: string) =>
    openMessage(
      'error',
      NotificationContent(
        i18n.t(k.NOTIFICATION__TABLE__LOADED__ERROR__FORMAT, {tableName})
      )
    ),
  // Restricted Custom Programs
  recommendUsersSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__ACCESS__GRANTED__PROGRAM__SUCCESS)
      )
    ),
  removeUserProgramAccessSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__ACCESS__REVOKED__PROGRAM__SUCCESS)
      )
    ),
  // Program with approval
  AddProgramWithApprovalSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRAM__REQUESTED__SUCCESS))
    ),
  AddProgramWithApprovalError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__RESOURCE_REQUEST__ERROR))
    ),
  // resources
  AddResourceWithApprovalSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__RESOURCE_REQUEST__SUCCESS))
    ),
  AddResourceWithApprovalError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__RESOURCE_REQUEST__ERROR))
    ),
  FetchResourceWithApprovalError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__GENERIC_ERROR))
    ),
  //Academies
  removeAcademySuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__ACADEMY__REMOVED__SUCCESS))
    ),
  removeAcademyError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__ACADEMY__REMOVED__ERROR))
    ),
  addStepsSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__PLURAL__ADDED__SUCCESS))
    ),
  addStepSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__ADDED__SUCCESS))
    ),
  updateStepSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__UPDATED__SUCCESS))
    ),
  updateStepError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__UPDATED__ERROR))
    ),
  addStepError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__STEP__ADDED__ERROR))
    ),
  academyPublishedSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__ACADEMY__PUBLISHED__SUCCESS))
    ),
  academyPublishedError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__ACADEMY__PUBLISHED__ERROR))
    ),
  academyUserAddedSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__USER__ADDED__ACADEMY__SUCCESS))
    ),
  academyUserAddedError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__USER__ADDED__ACADEMY__ERROR))
    ),
  // Cohort
  addCohortSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__COHORT__ADDED__SUCCESS))
    ),
  addCohortError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__COHORT__ADDED__ERROR))
    ),
  updateCohortSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__COHORT__UPDATED__SUCCESS))
    ),
  updateCohortError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__COHORT__UPDATED__ERROR))
    ),
  deleteCohortSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__COHORT__DELETED__SUCCESS))
    ),
  deleteCohortError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__COHORT__DELETED__ERROR))
    ),
  submitProjectSubmissionSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__SUBMISSION__SUBMITTED__SUCCESS)
      )
    ),
  submitProjectSubmissionError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__SUBMISSION__SUBMITTED__ERROR))
    ),
  submitCustomProgramProjectFeedbackSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__FEEDBACK__SUBMITTED__SUCCESS))
    ),
  submitCustomProgramProjectFeedbackError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__FEEDBACK__SUBMITTED__ERROR))
    ),
  //Card Issuer Terms
  CardIssuerTermsAcceptanceError: () =>
    openMessage('error', NotificationContent(i18n.t(k.ERROR__FRIENDLY))),
  //Licenses
  saveLicenseSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__LICENSE__SAVED__SUCCESS))
    ),
  saveLicenseError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__LICENSE__SAVED__ERROR))
    ),
  openLicenseError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__LICENSE__DETAILS__ERROR))
    ),
  removeLicenseSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__LICENSE__REMOVED__SUCCESS))
    ),
  removeLicenseError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__LICENSE__REMOVED__ERROR))
    ),
  addEventsToCalendarSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__EVENTS__ADDED__SUCCESS))
    ),
  addEventsToCalendarError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__EVENTS__ADDED__ERROR))
    ),
  markContentCompleteError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__PROGRESS__UPDATED__ERROR))
    ),
  updateCostCenterSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__COST_CENTER__UPDATED__SUCCESS))
    ),
  updateCostCenterError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__COST_CENTER__UPDATED__ERROR))
    ),
  deleteAcademyLevelSuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__SECTION__DELETED__SUCCESS))
    ),
  saveUpdateAcademyWelcomeEmailSuccess: () =>
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__ACADEMY__INVITATION_EMAIL_SUCCESS)
      )
    ),
  onLinkCopied: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__CTJ_LINK_COPIED))
    ),

  onUnenrollAcademySuccess: () =>
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__UNENROLL_ACADEMY_SUCCESS))
    ),

  onUnenrollAcademyError: () =>
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__UNENROLL_ACADEMY_ERROR))
    ),
  onFetchAcademyError: () => {
    openMessage(
      'error',
      NotificationContent(i18n.t(k.NOTIFICATION__FETCH_ACADEMY_ERROR))
    );
  },
  skillRatingSuccess: () => {
    openMessage(
      'success',
      NotificationContent(
        i18n.t(k.NOTIFICATION__SKILL_RATING_SUBMITTED__SUCCESS)
      )
    );
  },
  fileUploadSuccess: () => {
    openMessage(
      'success',
      NotificationContent(i18n.t(k.NOTIFICATION__FILE_UPLOAD_SUCCESS))
    );
  },
  bulkNudgeEmailSuccess: () => {
    openMessage(
      'success',
      NotificationContent(i18n.t(k.EMAIL__BULK_NUDGE_SUCCESSFUL))
    );
  },
  nudgeEmailSuccess: () => {
    openMessage(
      'success',
      NotificationContent(i18n.t(k.EMAIL__SINGLE_NUDGE_SUCCESSFUL))
    );
  },
};
