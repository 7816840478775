import {useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {useNavigate, useParams} from 'react-router-dom';
import AcademiesTable from './AcademiesTable';
import {Divider, Modal} from 'antd';
import {useDeleteAcademy} from '@hooks/apiEndpoints/academy/mutations';
import {
  useAcademiesCountsQuery,
  useAcademiesQuery,
  useDuplicateAcademyMutation,
} from '@generated/hooks';
import {editAcademyUrl} from '@utils/ClientPaths';
import {useMemo, useState} from 'react';
import {AcademyStatus} from '@generated/enums';
import {debounce} from 'lodash';
import Search from '@components/reusable/Search';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';
import LoadingSpinner from '@blocks/LoadingSpinner';
import EmptyDataText from '@blocks/NoDataText';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';

const {confirm} = Modal;
interface AcademiesTableContainerProps {
  search?: string;
  status?: AcademyStatus | undefined;
  permission?: string;
  showDevUtilSearch?: boolean;
  renderSearchBar?: () => JSX.Element;
  renderTitleBar?: (academiesExist: boolean) => JSX.Element;
  renderNewAcademyButton?: () => JSX.Element;
}

export const AcademiesTableContainer = (
  props: AcademiesTableContainerProps
) => {
  const {
    permission,
    renderNewAcademyButton,
    renderSearchBar,
    renderTitleBar,
    search,
    showDevUtilSearch,
    status,
  } = props;
  const isLxpAdmin = useIsLxpAdmin();
  const navigate = useNavigate();
  const [localSearchText, setSearchText] = useState<string>('');
  const [academiesExist, setAcademiesExist] = useState<boolean>(false);

  const [debouncedQueryParams, setDebouncedQueryParams] = useState<{
    isRestricted?: boolean;
    searchText?: string;
    status?: AcademyStatus;
  }>({});

  const debouncedUpdateQueryParams = useMemo(
    () =>
      debounce((newParams) => {
        setDebouncedQueryParams(newParams);
      }, 300),
    []
  );

  useEffect(() => {
    const params: {
      isRestricted?: boolean;
      searchText?: string;
      status?: AcademyStatus;
    } = {};

    if (search) {
      params.searchText = search;
    }
    if (status === AcademyStatus.Draft) {
      params.status = AcademyStatus.Draft;
    }
    if (status === AcademyStatus.Published) {
      params.status = AcademyStatus.Published;
    }
    if (permission === 'public') {
      params.isRestricted = false;
    }
    if (permission === 'restricted') {
      params.isRestricted = true;
    }

    debouncedUpdateQueryParams(params);
  }, [search, status, permission, debouncedUpdateQueryParams]);

  const {
    data: academies,
    isFetching: isAcademiesFetching,
    invalidateExact: invalidateAcademies,
    isFetched: isAcademiesFetched,
  } = useAcademiesQuery({
    queryParams: debouncedQueryParams,
  });

  useEffect(() => {
    if (academies?.length && !academiesExist) {
      setAcademiesExist(true);
    }
  }, [academies?.length]);

  const showSearchBar = isLxpAdmin && academiesExist;

  const params = useParams();
  useEffect(() => {
    invalidateAcademies();
  }, [params]);

  const {invalidateExact: invalidateAcademiesCount} = useAcademiesCountsQuery(
    null,
    {
      enabled: false,
    }
  );
  // Delete Custom Program
  const deleteAcademyMutation = useDeleteAcademy({
    onSuccess: () => {
      invalidateAcademies();
      invalidateAcademiesCount();
    },
  });

  const duplicateAcademyMutation = useDuplicateAcademyMutation();

  const handleClickDeleteProgram = async (id: number) => {
    confirm({
      icon: false,
      content: (
        <>
          <p>{i18n.t(k.ACADEMY__TABLE__DELETE_CONFIRM)} </p>
          <Divider
            style={{
              position: 'absolute',
              left: 0,
              bottom: '2.75rem',
            }}
          />
        </>
      ),
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.ACADEMY__DELETE),
      okType: 'danger',
      bodyStyle: {borderRadius: '25px'},
      title: i18n.t(k.ACADEMY__DELETE),
      okButtonProps: {type: 'primary', shape: 'round'},
      cancelButtonProps: {type: 'primary', shape: 'round', ghost: true},
      onOk() {
        deleteAcademyMutation.mutate({
          pathVars: {academyId: id},
        });
      },
    });
  };

  const handleDuplicateAcademy = (academyId: number) => {
    duplicateAcademyMutation.mutate(
      {pathVars: {academyId}},
      {
        onSuccess: (newAcademyId) => {
          navigate(editAcademyUrl(newAcademyId.toString()));
        },
      }
    );
  };

  const renderNotFoundImg = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center', padding: '24px'}}>
        <img
          src="https://res.cloudinary.com/dsmsrmuyr/image/upload/v1740759051/3_Cats_Curious_u2hvjc.png"
          style={{maxWidth: '100%', width: '600px'}}
          alt=""
        />
      </div>
    );
  };

  const academiesDataSource =
    isProductionAny || !localSearchText
      ? academies
      : academies?.filter((academy) => {
          return academy.name
            .toLowerCase()
            .includes(localSearchText.toLowerCase());
        });

  const renderContent = () => {
    if (!isAcademiesFetched) {
      return (
        <div
          style={{display: 'flex', justifyContent: 'center', padding: '24px'}}>
          <LoadingSpinner center size="large" />
        </div>
      );
    }
    if (!academies?.length) {
      if (academiesExist) {
        return (
          <div>
            {renderNotFoundImg()}
            <EmptyDataText
              header={i18n.t(k.SEARCH__NO_EXACT_MATCHES)}
              subHeader={i18n.t(k.SEARCH__TRY_CHANGE_FILTERS)}
            />
          </div>
        );
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {renderNotFoundImg()}
          <EmptyDataText
            style={{paddingBottom: '24px'}}
            header={i18n.t(k.ACADEMIES__NO_CREATED_ACADEMIES)}
            subHeader={i18n.t(k.ACADEMIES__CREATED_ACADEMIES_APPEAR_HERE)}
          />
          {renderNewAcademyButton?.() || null}
        </div>
      );
    }

    return (
      <AcademiesTable
        isLoading={isAcademiesFetching}
        dataSource={academiesDataSource || []}
        onClickDelete={handleClickDeleteProgram}
        onClickDuplicateAcademy={handleDuplicateAcademy}
      />
    );
  };

  return (
    <PageContentLayout fullWidth>
      {!isProductionAny && !isBetaAny && !!showDevUtilSearch && (
        <div style={{margin: '12px 0'}}>
          <Search
            buttonAriaLabel={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
              item: i18n.t(k.ACADEMY),
            })}
            id="academy-search-input"
            label={`${i18n.t(k.GENERIC__SEARCH)} (Developer Utility, Not Available in Production)`}
            onChange={setSearchText}
            value={localSearchText}
            placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
              item: i18n.t(k.ACADEMY),
            })}
          />
        </div>
      )}
      {renderTitleBar?.(academiesExist)}
      {!!showSearchBar ? renderSearchBar?.() || null : null}
      {renderContent()}
    </PageContentLayout>
  );
};
