import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {SkillAssessmentStepFooter} from './SkillAssessmentStepFooter';
import {
  useAcademiesLevelsLxpskillratingMutation,
  useLxpskillratingQuery,
  useLxpskillratingscaleQuery,
} from '@generated/hooks';
import {AcademySkillRatingVM} from '@generated/interfaces';

type ScaleItem = {
  levelDescription: string;
  skillLevel: string;
};

function formatScale(scale: ScaleItem[]) {
  return scale.map((s, i) => {
    return {
      value: i + 1,
      label: s.skillLevel,
      description: s.levelDescription,
    };
  });
}

interface SkillAssessmentStepFooterContainerProps {
  lxpRatingId: number;
  stepId: number;
  skillRatings: AcademySkillRatingVM[];
  handleSkillRatingSubmission: () => void;
}
export function SkillAssessmentStepFooterContainer({
  handleSkillRatingSubmission,
  lxpRatingId,
  stepId,
  skillRatings,
}: SkillAssessmentStepFooterContainerProps) {
  const latestSkillRating = skillRatings?.[0] || undefined;
  const {academyId} = useParams();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedRating, setSelectedRating] = useState<number | undefined>(
    undefined
  );
  const {data: lxpskillratingscale, isLoading: isLxpskillratingscaleLoading} =
    useLxpskillratingscaleQuery(
      {
        queryParams: {
          lxpTagId: lxpRatingId,
        },
      },
      {enabled: true}
    );

  /*
   * This fetches the up-to-date skill rating data from the lxp, and if there
   * is an existing rating submitted by the user, it will set the selected rating
   * to that value.
   *
   * We do this in addition to the skillRatings prop we receive from the ACM API
   * because the rating scale and naming can change on the LXP, and the ACM data
   * can potentially be stale.
   */
  const {invalidateExact: invalidateLxpskillrating} = useLxpskillratingQuery(
    {
      queryParams: {
        lxpRatingId: latestSkillRating?.lxpSkillRatingId || -1,
      },
    },
    {
      onSuccess: (e) => {
        setSelectedRating(parseInt(e?.level || '-1'));
      },
      onError: () => {
        setSelectedRating(latestSkillRating?.rating || 0);
      },
      enabled: (skillRatings?.length || 0) > 0,
    }
  );

  const academiesLevelsLxpskillrating =
    useAcademiesLevelsLxpskillratingMutation({
      onSuccess: () => {
        handleSkillRatingSubmission();
        invalidateLxpskillrating();
      },
    });

  return (
    <SkillAssessmentStepFooter
      isDisabled={latestSkillRating !== undefined}
      options={
        isLxpskillratingscaleLoading
          ? []
          : formatScale(lxpskillratingscale.skillLevels)
      }
      onSaveRating={(rating: number) => {
        setIsSubmitting(true);
        academiesLevelsLxpskillrating.mutate({
          payload: {
            contentId: stepId,
            rating,
            scale: lxpskillratingscale?.skillLevels?.length,
            skillTagId: 0, // 0 for now, this field may go away soon.
          },
          pathVars: {
            academyId,
            academyLevelId: stepId,
            lxpInputId: lxpRatingId,
          },
        });

        setSelectedRating(rating);
      }}
      loading={isSubmitting}
      value={selectedRating}
    />
  );
}
