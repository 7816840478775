import {
  ActionButtonMode,
  ActionButtonsContainerProps,
} from './ActionButtons.types';
import ActionButtonsView from './ActionButtons.view';
import {usePreapprovedPlanItemsQuery} from '@generated/hooks';
import {i18n, k} from '@i18n/translate';
import {FinanceType} from '@generated/enums';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {LxpPaths} from '@utils/lxpPaths';
import {financeDetailPath, sendToUserPrograms} from '@utils/ClientPaths';
import {useLxpDomain} from '@hooks/integrated/useGetLxpDomain';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useIntegratedMode} from '@hooks/integrated/useIntegratedMode';

function ActionButtonsContainer({
  financeIncentiveId,
  financeType,
  navigate,
  setShowReimbursement,
  showActiveCard,
  showExploreProgramButton,
  userPlanItemId,
}: ActionButtonsContainerProps) {
  const {user} = useAuth();
  const {isFeatureFlagOn} = useFeatureFlags();
  const isLearnInReimbursementsOn = isFeatureFlagOn.LearnInReimbursements;
  const {data: preapprovedPlanItems} = usePreapprovedPlanItemsQuery(null);
  const lxpDomain = useLxpDomain();
  const isIntegratedMode = useIntegratedMode(user);

  const ctaButtonLabel = ((): string => {
    if (!showActiveCard) return i18n.t(k.PLB__TITLE);
    if (
      financeType === FinanceType.ExternalReimbursement ||
      financeType === FinanceType.TuitionReimbursement
    ) {
      return i18n.t(k.REIMBURSEMENT__PLURAL__MANAGE);
    }
    return i18n.t(k.TRANSACTION__PLURAL__VIEW);
  })();

  const showCtaButton =
    (showExploreProgramButton && !showActiveCard) || showActiveCard;

  const ctaButtonMode: ActionButtonMode = (() => {
    if (isFeatureFlagOn.LxpToCmFlow || isIntegratedMode)
      return ActionButtonMode.LxpMarketplace;
    if (showActiveCard) return ActionButtonMode.ActiveCard;
    return ActionButtonMode.Default;
  })();

  const onCtaButtonClick = () => {
    switch (ctaButtonMode) {
      case ActionButtonMode.LxpMarketplace:
        return (location.href = `${lxpDomain}${LxpPaths.Marketplace}`);
      case ActionButtonMode.ActiveCard:
        return navigate(financeDetailPath(userPlanItemId));
      default:
        return navigate(sendToUserPrograms());
    }
  };

  return (
    <ActionButtonsView
      ctaButtonLabel={ctaButtonLabel}
      financeIncentiveId={financeIncentiveId}
      financeType={financeType}
      isLearnInReimbursementsOn={isLearnInReimbursementsOn}
      ctaButtonMode={ctaButtonMode}
      onCtaButtonClick={onCtaButtonClick}
      preapprovedPlanItems={preapprovedPlanItems}
      push={navigate}
      setShowReimbursement={setShowReimbursement}
      showCtaButton={showCtaButton}
      showExploreProgramButton={showExploreProgramButton}
    />
  );
}

export default ActionButtonsContainer;
