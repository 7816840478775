import {useQuery, UseQueryOptions} from 'react-query';
import React from 'react';

/**
 * usePolling is a hook that allows you to poll an API endpoint and stop polling when the user is idle.
 * It is useful for polling an API endpoint while also avoiding unnecessary polling when the user is idle.
 * It is a wrapper around the useQuery hook using the refetchInterval to poll the API endpoint.
 * @param id - The id of the query
 * @param poll - The function to poll
 * @param refetchInterval - The interval to poll (default: 10 seconds)
 * @param autoStart - Whether to start polling automatically
 * @param idleTimeout - The timeout for considering the user idle (default: 2 mins)
 */

interface IdlePollingOptions<TData>
  extends Omit<UseQueryOptions<TData, Error>, 'queryKey' | 'queryFn'> {
  id: string;
  poll: (id: string) => Promise<TData>;
  refetchInterval?: number;
  enabled?: boolean;
  autoStart?: boolean;
  idleTimeout?: number; // How long before considering user idle
}

export const usePolling = <TData>({
  id,
  poll,
  refetchInterval = 10 * 1000, // 10 seconds
  autoStart = false,
  idleTimeout = 2 * 60 * 1000, // 2 minutes idle timeout
  ...queryOptions
}: IdlePollingOptions<TData>) => {
  const [isPollingEnabled, setIsPollingEnabled] = React.useState(autoStart);
  const [isIdle, setIsIdle] = React.useState(false);
  const idleTimeoutRef = React.useRef<NodeJS.Timeout>();

  // Handle idle state
  React.useEffect(() => {
    const resetIdleTimer = () => {
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
      setIsIdle(false);
      idleTimeoutRef.current = setTimeout(() => setIsIdle(true), idleTimeout);
    };

    // Set up event listeners for user activity
    const events = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];
    events.forEach((event) => {
      window.addEventListener(event, resetIdleTimer);
    });

    // Initial timer
    resetIdleTimer();

    // Start polling if autoStart is enabled
    if (autoStart) {
      startPolling();
    }

    return () => {
      stopPolling();
      events.forEach((event) => {
        window.removeEventListener(event, resetIdleTimer);
      });
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoStart]);

  const {refetch} = useQuery({
    queryKey: ['polling', id],
    queryFn: () => poll(id),
    refetchInterval: isPollingEnabled && !isIdle ? refetchInterval : false,
    enabled: isPollingEnabled,
    ...queryOptions,
  });

  const startPolling = React.useCallback(() => {
    setIsPollingEnabled(true);
    // Force immediate refetch when starting polling
    refetch();
  }, [refetch]);

  const stopPolling = React.useCallback(() => {
    setIsPollingEnabled(false);
  }, []);

  return {
    startPolling,
    stopPolling,
  };
};
