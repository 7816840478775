import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {IncentiveAvailability} from '@components/user/store/interface';
import {COLORS} from '@utils/constants';
import {useLxpDomain} from '@hooks/integrated/useGetLxpDomain';
import {LxpPaths} from '@utils/lxpPaths';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useIntegratedMode} from '@hooks/integrated/useIntegratedMode';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Header = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 3px;
`;

const Subheader = styled.div`
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 150%;
  color: ${COLORS.Neutral950};
  margin-bottom: 16px;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IEligibleProgramsTabProps {
  incentiveProgramTypes?: {id: number; description: string}[];
  availability?: IncentiveAvailability;
}

function EligibleProgramsTab({
  incentiveProgramTypes,
  availability,
}: IEligibleProgramsTabProps) {
  const navigate = useNavigate();
  const {user} = useAuth();
  const isIntegratedMode = useIntegratedMode(user);
  const lxpDomain = useLxpDomain();

  const isAvailableForAllPrograms =
    availability === IncentiveAvailability.AvailableToAll;
  const isAvailableForNoPrograms =
    availability === IncentiveAvailability.NotAvailable;

  const handleClickViewEligiblePrograms = () => {
    if (isIntegratedMode) {
      return (location.href = `${lxpDomain}${LxpPaths.Marketplace}`);
    } else if (isAvailableForAllPrograms) {
      navigate('/user/programs');
    } else if (incentiveProgramTypes?.length) {
      navigate('/user/programs', {
        state: {incentiveProgramTypes: incentiveProgramTypes.map(({id}) => id)},
      });
    }
  };

  const renderIncentiveDescription = (): string => {
    if (!!incentiveProgramTypes?.length) {
      const availablePrograms = incentiveProgramTypes
        .map((item) => item.description)
        .join(', ')
        .replace(/, ([^,]*)$/, ' and $1');
      return i18n.t(k.INCENTIVE__AVAILABLE__ONLY__FORMAT, {
        item: availablePrograms,
      });
    } else if (isAvailableForNoPrograms) {
      return i18n.t(k.INCENTIVE__UNAVAILABLE__ANY);
    } else if (isAvailableForAllPrograms) {
      return i18n.t(k.INCENTIVE__AVAILABLE__ALL);
    }
  };

  return (
    <>
      <Header>{i18n.t(k.INCENTIVE__PROGRAMS_AVAILABLE)}</Header>
      <Subheader>{renderIncentiveDescription()}</Subheader>
      {!isAvailableForNoPrograms && (
        <LearnInButton
          tag={ButtonTags.Secondary}
          onClick={handleClickViewEligiblePrograms}
          style={{marginBottom: '24px'}}>
          {i18n.t(k.PROGRAM__VIEW_ELIGIBLE)}
        </LearnInButton>
      )}
    </>
  );
}

export default EligibleProgramsTab;
