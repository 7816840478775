import {UserDetailsVM} from '@generated/interfaces';
import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';
import {
  AdminPaths,
  UserPaths,
  UserProgramsPageTab,
  getUserProgramsBasePath,
} from '@utils/ClientPaths';
import {useIntegratedMode} from './useIntegratedMode';

export interface UrlPath {
  path: string;
  enabled?: (user?: UserDetailsVM) => boolean;
  alwaysHidden?: boolean;
}

export const useGetUrlIncludesList = (
  user: UserDetailsVM,
  isFeatureFlagOn: TIsFeatureFlagOn
): UrlPath[] => {
  const isIntegratedMode = useIntegratedMode(user);

  const AcmNavFlags =
    isFeatureFlagOn.HidePrimaryNav ||
    isFeatureFlagOn.AcademyAndCpLxpFlow ||
    isFeatureFlagOn.LxpToCmFlow ||
    isIntegratedMode;

  const commonEnabledExpression =
    isFeatureFlagOn.LxpToCmFlow || isIntegratedMode;

  return [
    {
      path: getUserProgramsBasePath(UserProgramsPageTab.Custom),
      enabled: () => AcmNavFlags,
    },
    {
      path: UserPaths.Academies,
      enabled: () => AcmNavFlags,
    },
    {
      path: UserPaths.WelcomeAcademy,
      enabled: () => AcmNavFlags,
    },
    {
      path: UserPaths.Index,
      enabled: () => commonEnabledExpression,
    },
    {
      path: UserPaths.ManagePlan,
      enabled: () => commonEnabledExpression,
    },
    {
      path: UserPaths.ProgramDetail,
      enabled: () => commonEnabledExpression,
    },
    {
      path: UserPaths.Settings,
      enabled: () => commonEnabledExpression,
    },
    {
      path: AdminPaths.Academies,
      enabled: () => true,
    },
    {
      path: UserPaths.AcademyNotFound,
      enabled: () => true,
      alwaysHidden: true,
    },
  ];
};
