import {useState, useEffect} from 'react';
import {BulkNudgeDrawerContainerProps} from './BulkNudgeDrawer.interfaces';
import BulkNudgeDrawerView from './BulkNudgeDrawer.view';
import {debounce} from 'lodash';
import {
  useAcademiesUsersQuery,
  useAcademiesUsersAllNudgeMutation,
  useCustomProgramsUsersQuery,
  useCustomProgramsUsersAllNudgeMutation,
} from '@generated/hooks';
import {useParams} from 'react-router-dom';
import {AcademyUserVM, CustomProgramUserResponse} from '@generated/interfaces';
import NudgeEmailPreview from '@blocks/NudgeEmailPreview';
import {CustomizableLearningCategory} from '@generated/enums';
import {notify} from '@components/user/notifications';

function BulkNudgeDrawerContainer(props: BulkNudgeDrawerContainerProps) {
  const {
    contentId,
    feature,
    customizableLearningCategory,
    bypassParticipantsList,
    participantsCount,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [showList, setShowList] = useState(true);
  const [participants, setParticipants] = useState<
    (AcademyUserVM | CustomProgramUserResponse)[]
  >([]);
  const [nudgeEmailPreviewOpen, setNudgeEmailPreviewOpen] = useState(false);

  const isAcademy =
    customizableLearningCategory === CustomizableLearningCategory.Academy;
  const isCustomProgram =
    customizableLearningCategory === CustomizableLearningCategory.CustomProgram;

  // Get participants
  const {academyId, academyStepId, customProgramId} = useParams<{
    academyId?: string;
    academyStepId?: string;
    customProgramId?: string;
  }>();

  // Get Academy Users
  const {data: academiesUsers, isLoading: isAcademiesUsersLoading} =
    useAcademiesUsersQuery(
      {
        academyId: parseInt(academyId),
        queryParams: {
          incompleteStepId: parseInt(academyStepId),
        },
      },
      {
        enabled: !bypassParticipantsList && isAcademy && !!academyId,
        onSuccess: (data) => setParticipants(data),
      }
    );

  // Get Custom Program Users
  const {data: customProgramsUsers, isLoading: isCustomProgramsUsersLoading} =
    useCustomProgramsUsersQuery(
      {
        customProgramId: parseInt(customProgramId),
        queryParams: {
          incompleteContentId: contentId,
        },
      },
      {
        enabled:
          !bypassParticipantsList && isCustomProgram && !!customProgramId,
        onSuccess: (data) => setParticipants(data),
      }
    );

  // FIlter Participants
  const filterParticipants = debounce(() => {
    if (isAcademy) {
      if (!searchValue) return setParticipants(academiesUsers);
      setParticipants(
        academiesUsers?.filter((user) => {
          return user.name.toLowerCase().includes(searchValue.toLowerCase());
        })
      );
    }
    if (isCustomProgram) {
      if (!searchValue) return setParticipants(customProgramsUsers);
      setParticipants(
        customProgramsUsers?.filter((user) => {
          return user.userName
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        })
      );
    }
  }, 300);

  useEffect(() => {
    filterParticipants();
  }, [searchValue, academiesUsers, customProgramsUsers, filterParticipants]);

  const participantsTotalCount = (() => {
    if (participantsCount) {
      return participantsCount;
    }
    if (isAcademy) {
      return academiesUsers?.length || 0;
    }
    if (isCustomProgram) {
      return customProgramsUsers?.length || 0;
    }
  })();

  const handleCloseEmailPreview = () => {
    setNudgeEmailPreviewOpen(false);
    if (bypassParticipantsList) {
      props.onClose();
    }
  };

  // Send Bulk Nudges
  const academiesUsersAllNudge = useAcademiesUsersAllNudgeMutation({
    onSuccess: () => {
      props.onClose();
      handleCloseEmailPreview();
      notify.bulkNudgeEmailSuccess();
    },
  });

  const customProgramsUsersAllNudge = useCustomProgramsUsersAllNudgeMutation({
    onSuccess: () => {
      props.onClose();
      handleCloseEmailPreview();
      notify.bulkNudgeEmailSuccess();
    },
  });

  const handleSendNudge = ({
    subject,
    note,
  }: {
    subject: string;
    note: string;
  }) => {
    if (isAcademy) {
      academiesUsersAllNudge.mutate({
        payload: {
          stepId: parseInt(academyStepId),
          subject,
          note,
        },
        pathVars: {
          academyId: parseInt(academyId),
        },
      });
    }
    if (isCustomProgram) {
      customProgramsUsersAllNudge.mutate({
        payload: {
          subject,
          note,
          contentId,
        },
        pathVars: {
          customProgramId: parseInt(customProgramId),
        },
      });
    }
  };

  return (
    <>
      <BulkNudgeDrawerView
        isLoading={isAcademiesUsersLoading || isCustomProgramsUsersLoading}
        participants={
          participants?.map(
            (p) =>
              (p as AcademyUserVM).name ||
              (p as CustomProgramUserResponse).userName
          ) || []
        }
        participantsTotalCount={participantsTotalCount}
        searchValue={searchValue}
        onChangeSearch={(val: string) => setSearchValue(val)}
        showList={showList}
        onClickShowList={() => setShowList((state) => !state)}
        onClickPreview={() => setNudgeEmailPreviewOpen(true)}
        {...props}
        visible={props.visible && !bypassParticipantsList} // if we bypass this list, we never want to show this drawer
      />
      <NudgeEmailPreview
        open={
          nudgeEmailPreviewOpen || (props.visible && bypassParticipantsList) // if we bypass participants list, we always only want to show this drawer
        }
        onClose={handleCloseEmailPreview}
        participantsCount={participantsTotalCount}
        showParticipantsCount
        programOrAcademyName={props.name}
        feature={feature}
        onSendNudge={handleSendNudge}
      />
    </>
  );
}

export default BulkNudgeDrawerContainer;
