import {useForceHidePrimaryNavigation} from '@hooks/integrated/useForceHidePrimaryNavigation';
import {useHidePrimaryNavigation} from '@hooks/integrated/useHidePrimaryNavigation';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import {useCanManageApprovals} from '@hooks/useCanManageApprovals';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useFlagsHaveLoaded} from '@hooks/useFlagsHaveLoaded';
import {CheckUserStatusResponseData} from '@models/serverModels';
import {getCheckUserStatusRq} from '@store/apiEndpoints';
import {cacheForHours, simpleQueryFn} from '@store/queryClient';
import {initZenDesk} from '@utils/chat/chat-utils';
import {initCredSpark} from '@utils/credSpark/credspark-utils';
import {useAuth} from '@utils/oidc-auth-wrapper';
import PrimaryRoutes from 'PrimaryRoutes';
import {useEffect} from 'react';
import {useQuery} from 'react-query';

const PrimaryRoutesContainer = ({}) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {user} = useAuth();

  const flagsReady = useFlagsHaveLoaded(isFeatureFlagOn);
  const isCredSparkDemoEnabled = isFeatureFlagOn.CredsparkDemo;
  const isChatEnabled = !isFeatureFlagOn.HideChatWidget;
  const isApprover = useCanManageApprovals(user);
  const isLxpAdmin = useIsLxpAdmin();
  const forceNavOff = useForceHidePrimaryNavigation(
    user,
    isFeatureFlagOn,
    isApprover,
    isLxpAdmin
  );

  const hidePrimaryNavigation = useHidePrimaryNavigation({
    user,
    location: window.location,
    forceOff: forceNavOff,
  });

  const checkUserStatusRq = getCheckUserStatusRq();
  useQuery<CheckUserStatusResponseData>(
    checkUserStatusRq.queryKey,
    () => simpleQueryFn(checkUserStatusRq.path),
    {
      ...cacheForHours(6),
    }
  );

  useEffect(() => {
    if (!flagsReady) {
      return;
    }
    if (isChatEnabled) {
      initZenDesk(user);
    }
    if (isCredSparkDemoEnabled) {
      initCredSpark();
    }
  }, [flagsReady, isCredSparkDemoEnabled, isChatEnabled, user]);

  /* We're using the Apollo flags to determine which layout to show. On first look, this seems redundant since the Apollo layout code has the ACM Apollo flag passed in. However, the issue is that flag is only checked in the layout.hook when lxpOptions are available. Since lxpOptions are always null for non-LXP users, those users never have the flag checked, so it defaults to on even if the flag is off. If all users are LXP users, this isn't a problem, but for as long as we have standalone ACM users and the checks are nested under lxpOptions, the flag check at this level needs to remain. */
  const hasAcmLayoutFlag = isFeatureFlagOn.ACMLayout;
  const hasLxpAdminLayout = isLxpAdmin && isFeatureFlagOn.LXPAdminLayout;

  return (
    <PrimaryRoutes
      flagsReady={flagsReady}
      hidePrimaryNavigation={hidePrimaryNavigation}
      isLxpAdmin={isLxpAdmin}
      hasAcmLayoutFlag={hasAcmLayoutFlag}
      hasLxpAdminLayout={hasLxpAdminLayout}
    />
  );
};

export default PrimaryRoutesContainer;
