import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {i18n, k} from '@i18n/translate';

export const RemoveMemberButton = ({
  handleRemoveUser,
  userCompanyId,
}: {
  handleRemoveUser: (userCompanyId: number) => void;
  userCompanyId: number;
}) => {
  return (
    <LearnInButton
      tag={ButtonTags.SecondaryNoBorderNoFocusOutline}
      onClick={() => {
        handleRemoveUser(userCompanyId);
      }}>
      {i18n.t(k.CTA__REMOVE)}
    </LearnInButton>
  );
};
