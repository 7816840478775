import {ResourceSkillType} from './../enums';

import {queryHookFactory, addQueryParams} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/SkillsController.cs

/*
|--------------------------------------------------------------------------
|  lxpskillrating 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useLxpskillratingQuery} from '@generated/hooks';
 *
 *  const {
 *    data: lxpskillrating,
 *    isLoading: isLxpskillratingLoading,
 *    invalidateExact: invalidateLxpskillrating,
 *  } = useLxpskillratingQuery({
 *    queryParams: {
 *        lxpRatingId
 *    }
 *  })
 *
 */

export const useLxpskillratingQuery = queryHookFactory<
  {queryParams?: {lxpRatingId?: number}},
  any // return type
>(
  'useLxpskillratingQuery',
  ({queryParams}) =>
    addQueryParams(() => `skills/lxpskillrating`, null, queryParams),
  {responseSchema: z.any()}
);

// CACHED
export const useLxpskillratingQueryCached = queryHookFactory<
  {queryParams?: {lxpRatingId?: number}},
  any // return type
>(
  'useLxpskillratingQuery',
  ({queryParams}) =>
    addQueryParams(() => `skills/lxpskillrating`, null, queryParams),
  {responseSchema: z.any()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  lxpskillratingscale 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useLxpskillratingscaleQuery} from '@generated/hooks';
 *
 *  const {
 *    data: lxpskillratingscale,
 *    isLoading: isLxpskillratingscaleLoading,
 *    invalidateExact: invalidateLxpskillratingscale,
 *  } = useLxpskillratingscaleQuery({
 *    queryParams: {
 *        lxpTagId
 *    }
 *  })
 *
 */

export const useLxpskillratingscaleQuery = queryHookFactory<
  {queryParams?: {lxpTagId?: number}},
  any // return type
>(
  'useLxpskillratingscaleQuery',
  ({queryParams}) =>
    addQueryParams(() => `skills/lxpskillratingscale`, null, queryParams),
  {responseSchema: z.any()}
);

// CACHED
export const useLxpskillratingscaleQueryCached = queryHookFactory<
  {queryParams?: {lxpTagId?: number}},
  any // return type
>(
  'useLxpskillratingscaleQuery',
  ({queryParams}) =>
    addQueryParams(() => `skills/lxpskillratingscale`, null, queryParams),
  {responseSchema: z.any()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  {resourceId}/associated/{type} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSkillsAssociatedByIdQuery} from '@generated/hooks';
 *
 *  const {
 *    data: skillsAssociatedById,
 *    isLoading: isSkillsAssociatedByIdLoading,
 *    invalidateExact: invalidateSkillsAssociatedById,
 *  } = useSkillsAssociatedByIdQuery({
 *    resourceId,
 *    type
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useSkillsAssociatedByIdQuery = queryHookFactory<
  {resourceId: number; type: ResourceSkillType}, // path variables
  any[] // return type
>(
  'useSkillsAssociatedByIdQuery',
  ({resourceId, type}) => `skills/${resourceId}/associated/${type}`,
  {responseSchema: z.array(z.any())}
);

// CACHED
export const useSkillsAssociatedByIdQueryCached = queryHookFactory<
  {resourceId: number; type: ResourceSkillType}, // path variables
  any[] // return type
>(
  'useSkillsAssociatedByIdQuery',
  ({resourceId, type}) => `skills/${resourceId}/associated/${type}`,
  {responseSchema: z.array(z.any())},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSkillsSearchQuery} from '@generated/hooks';
 *
 *  const {
 *    data: skillsSearch,
 *    isLoading: isSkillsSearchLoading,
 *    invalidateExact: invalidateSkillsSearch,
 *  } = useSkillsSearchQuery({
 *    queryParams: {
 *        skip,
 *        take,
 *        term
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useSkillsSearchQuery = queryHookFactory<
  {queryParams?: {skip?: number; take?: number; term?: string}},
  any // return type
>(
  'useSkillsSearchQuery',
  ({queryParams}) => addQueryParams(() => `skills/search`, null, queryParams),
  {responseSchema: z.any()}
);

// CACHED
export const useSkillsSearchQueryCached = queryHookFactory<
  {queryParams?: {skip?: number; take?: number; term?: string}},
  any // return type
>(
  'useSkillsSearchQuery',
  ({queryParams}) => addQueryParams(() => `skills/search`, null, queryParams),
  {responseSchema: z.any()},
  {staleTime: 600000}
);
