import {i18n, k} from '@i18n/translate';
import {getIncentivesCountRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {IncentivesCountVM} from '@models/serverModels';
import {UserPaths} from '@utils/ClientPaths';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {cacheForeverOptions, simpleQueryFn} from '@store/queryClient';
import {useAcademiesCountsQuery} from '@generated/hooks';
import useCanShowPrograms from './useCanShowPrograms';
import useFeatureFlags from './useFeatureFlags';
import {useUrlContainsIndicator} from './integrated/useUrlContainsIndicator';
import {useCanManageApprovals} from './useCanManageApprovals';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useIntegratedMode} from './integrated/useIntegratedMode';

export interface NavMenuItem {
  label: string;
  path: UserPaths;
}

function useGetExploreSubMenuItems(): NavMenuItem[] {
  const {user} = useAuth();
  const {isFeatureFlagOn} = useFeatureFlags();
  const isIntegratedMode = useIntegratedMode(user);
  const urlContainsIndicator = useUrlContainsIndicator(
    window.location.href,
    isFeatureFlagOn.LXPAdminLayout
  );

  const canManageApprovals = useCanManageApprovals(user);

  const approversWithoutExplore =
    (isFeatureFlagOn.LxpToCmFlow || isIntegratedMode) &&
    urlContainsIndicator &&
    canManageApprovals;

  const isTimeIncentivesOn =
    useTreatment(FeatureFlagExperiments.TimeIncentives) ===
    FeatureFlagTreatments.On;

  const isAcademiesOn =
    useTreatment(FeatureFlagExperiments.Academies) ===
      FeatureFlagTreatments.On && user?.canViewAcademiesFeature;
  const canShowPrograms = useCanShowPrograms();

  const incentivesCountRq = getIncentivesCountRq();
  const incentivesCountQuery = useQuery<IncentivesCountVM>(
    incentivesCountRq.queryKey,
    () => simpleQueryFn(incentivesCountRq.path),
    cacheForeverOptions
  );

  const academiesCountQuery = useAcademiesCountsQuery(null, {
    enabled: isAcademiesOn,
  });

  const userNavMenuItems: NavMenuItem[] = [];
  if (incentivesCountQuery.isSuccess && canShowPrograms.any) {
    userNavMenuItems.push({
      label: i18n.t(k.PROGRAM__PLURAL),
      path: UserPaths.ProgramsSearch,
    });
  }
  if (
    incentivesCountQuery.isSuccess &&
    !!incentivesCountQuery.data?.financeIncentivesAvailableToUsersCount
  ) {
    userNavMenuItems.push({
      label: i18n.t(k.GENERIC__FINANCING),
      path: UserPaths.FinanceIncentivesSearch,
    });
  }
  if (
    isTimeIncentivesOn &&
    incentivesCountQuery.isSuccess &&
    !!incentivesCountQuery.data?.timeIncentivesAvailableToUsersCount
  ) {
    userNavMenuItems.push({
      label: i18n.t(k.TIME),
      path: UserPaths.TimeIncentivesSearch,
    });
  }
  if (
    isAcademiesOn &&
    academiesCountQuery.isSuccess &&
    !!academiesCountQuery.data?.publishedAcademiesAvailableToUserCount
  ) {
    userNavMenuItems.push({
      label: i18n.t(k.ACADEMY__PLURAL),
      path: UserPaths.Academies,
    });
  }

  return approversWithoutExplore ? [] : userNavMenuItems;
}

export default useGetExploreSubMenuItems;
