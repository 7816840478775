import {
  TeamInsightEmployeeDetailVm,
  TeamInsightFullOverviewVM,
} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {TeamInsightFullOverviewVMSchema} from '../zodSchemas/TeamInsightFullOverviewVMSchema.schema';
import {TeamInsightEmployeeDetailVmSchema} from '../zodSchemas/TeamInsightEmployeeDetailVmSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Reports/TeamInsightsController.cs

/*
|--------------------------------------------------------------------------
|  get-team-insight-detail/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTeamInsightDetailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: teamInsightDetail,
 *    isLoading: isTeamInsightDetailLoading,
 *    invalidateExact: invalidateTeamInsightDetail,
 *  } = useTeamInsightDetailQuery({
 *    userCompanyId
 *  })
 *
 */

export const useTeamInsightDetailQuery = queryHookFactory<
  {userCompanyId: number | string}, // path variables
  TeamInsightEmployeeDetailVm // return type
>(
  'useTeamInsightDetailQuery',
  ({userCompanyId}) => `team-insights/get-team-insight-detail/${userCompanyId}`,
  {responseSchema: TeamInsightEmployeeDetailVmSchema}
);

// CACHED
export const useTeamInsightDetailQueryCached = queryHookFactory<
  {userCompanyId: number | string}, // path variables
  TeamInsightEmployeeDetailVm // return type
>(
  'useTeamInsightDetailQuery',
  ({userCompanyId}) => `team-insights/get-team-insight-detail/${userCompanyId}`,
  {responseSchema: TeamInsightEmployeeDetailVmSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-team-insight-overview 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTeamInsightOverviewQuery} from '@generated/hooks';
 *
 *  const {
 *    data: teamInsightOverview,
 *    isLoading: isTeamInsightOverviewLoading,
 *    invalidateExact: invalidateTeamInsightOverview,
 *  } = useTeamInsightOverviewQuery(null)
 *
 */

export const useTeamInsightOverviewQuery = queryHookFactory<
  null,
  TeamInsightFullOverviewVM // return type
>(
  'useTeamInsightOverviewQuery',
  () => `team-insights/get-team-insight-overview`,
  {responseSchema: TeamInsightFullOverviewVMSchema}
);

// CACHED
export const useTeamInsightOverviewQueryCached = queryHookFactory<
  null,
  TeamInsightFullOverviewVM // return type
>(
  'useTeamInsightOverviewQuery',
  () => `team-insights/get-team-insight-overview`,
  {responseSchema: TeamInsightFullOverviewVMSchema},
  {staleTime: 600000}
);
