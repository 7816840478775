import {Progress} from 'antd';
import {COLORS} from '@utils/constants';
import {RatingOption} from './TemporaryConstants';
import styled from 'styled-components';

const RatingTitle = styled.p`
  font-size: 1rem !important;
  line-height: none;
  margin-bottom: 0px;
  color: ${COLORS.Neutral800};
`;
const RatingDescription = styled.p`
  margin-bottom: 0px;
  font-size: 0.75rem !important;
  line-height: none;
  white-space: wrap;
  color: ${COLORS.Neutral600};
`;

const RatingNumber = styled.p`
  font-size: 1rem !important;
  line-height: none;
  margin-bottom: 0px;
  color: ${COLORS.Neutral800}
  font-weight: 600;
`;

const Dot = styled.div`
  height: 16px;
  width: 16px;
  min-width: 16px;
  border: 1px solid ${COLORS.Neutral300};
  border-radius: 100%;
`;

const SelectedDot = styled(Dot)`
  background-color: ${COLORS.Blue800};
  border-color: ${COLORS.Blue800};
`;

const SkillRatingOptionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

const SkillRatingOptionTextContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
`;

interface SkillAssessmentOptionProps {
  option: RatingOption;
  isSelected: boolean;
  isOpen: boolean;
  ratingCount: number;
}
export function SkillRatingOption({
  ratingCount,
  option,
  isSelected,
  isOpen,
}: SkillAssessmentOptionProps) {
  return isOpen ? (
    <SkillRatingOptionContainer>
      <Progress
        width={36}
        strokeColor={COLORS.Blue800}
        strokeLinecap="round"
        strokeWidth={10}
        trailColor={COLORS.Neutral100}
        status="normal"
        format={(percent) => (
          <RatingNumber>{`${percent / (100 / ratingCount)}`}</RatingNumber>
        )}
        type="circle"
        percent={option.value * (100 / ratingCount)}
      />
      <div style={{display: 'flex', gap: '7px', alignItems: 'center'}}>
        <SkillRatingOptionTextContainer>
          <RatingTitle>{option.label}</RatingTitle>
          <RatingDescription>{option.description}</RatingDescription>
        </SkillRatingOptionTextContainer>
        {isSelected ? <SelectedDot /> : <Dot />}
      </div>
    </SkillRatingOptionContainer>
  ) : (
    <p>
      Level {option.value} - {option.label}
    </p>
  );
}
